import { Vehicle } from './codegen/vehicles';
import { Transaction } from './codegen/transactions';
import { Booking } from './codegen/bookings';

export type PartialPick<T, F extends keyof T> = Omit<T, F> &
  Partial<Pick<T, F>>;

export type TransactionWithVehicle = {
  transaction?: Transaction;
  vehicleWithGroups?: VehicleWithGroups;
};

export type BookingWithVehicle = Booking & {
  vehicle?: VehicleWithGroups;
};

export type VehicleWithGroups = Vehicle & {
  groups: VehicleGroup[];
};

export enum BackendErrors {
  INVALID_FUEL_CARD = 'INVALID_FUEL_CARD',
  EXTERNAL_LOGIN_ERROR = 'EXTERNAL_LOGIN_ERROR',
}

export type VehicleGroup = {
  id: string;
  name: string;
};

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const mergeVehicleWithGroups = (
  vehicle: Vehicle,
  groups: VehicleGroup[],
): VehicleWithGroups => {
  const enrichedGroups = vehicle.group_ids?.map((vehicleGroupId) => {
    const name = groups.find((g) => g.id === vehicleGroupId)?.name || '';
    return {
      ...vehicle,
      name,
    };
  });
  return {
    ...vehicle,
    groups: enrichedGroups || [],
  };
};
