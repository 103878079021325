import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { UNIT_TYPE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { Transaction, TransactionItem } from '../../../../codegen/transactions';
import { SidebarRow } from '../../../../components/sidebar/SidebarRow';
import { SidebarHeadline } from '../../../../components/sidebar/SidebarHeadline';
import { FormattedNumber } from 'react-intl';

type TransactionAmountsTableProps = {
  transaction: Transaction;
};

export const TransactionAmountsTable = ({
  transaction,
}: TransactionAmountsTableProps) => {
  const hasItems = transaction.items.length > 0;

  return (
    <div data-testid={'transactions-sidebar-amounts'}>
      {hasItems && (
        <h6 className="padding-left-10 margin-top-20">
          <CustomFormattedMessage id="common.transaction.items" />
        </h6>
      )}
      <table className="table table-condensed">
        <colgroup>
          <col style={{ width: 150 }} />
          <col />
        </colgroup>
        <tbody>
          {hasItems &&
            transaction.items.map((item, idx) => (
              <TransactionItemRows
                item={item}
                currency={transaction.currency}
                key={idx}
              />
            ))}
          {!!transaction.totalAmountNet && !!transaction.currency && (
            <SidebarRow
              label="common.transaction.totalPriceGross"
              highlight
            >
              <MonetaryValue
                amount={parseFloat(transaction.totalAmountGross!)}
                currency={transaction.currency}
              />
            </SidebarRow>
          )}
          {!!transaction.totalAmountGross &&
            !!transaction.totalAmountNet &&
            !!transaction.currency && (
              <SidebarRow
                label="common.transaction.inclTax"
                labelValues={{ vat: `${transaction.items[0].vat}` }}
              >
                <MonetaryValue
                  amount={
                    parseFloat(transaction.totalAmountGross) -
                    parseFloat(transaction.totalAmountNet)
                  }
                  currency={transaction.currency}
                />
              </SidebarRow>
            )}
        </tbody>
      </table>
    </div>
  );
};

type TransactionItemRowsProps = {
  item: TransactionItem;
  currency?: string;
};

const TransactionItemRows = ({ item, currency }: TransactionItemRowsProps) => {
  return (
    <>
      <SidebarHeadline>{item.description}</SidebarHeadline>
      <SidebarRow label="common.transaction.quantity">
        <FormattedNumber
          value={parseFloat(item.quantity)}
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
      </SidebarRow>
      <SidebarRow label="common.transaction.unit">
        <CustomFormattedMessage id={UNIT_TYPE_TO_TRANSLATION_KEY[item.unit]} />
      </SidebarRow>
      {!!currency && (
        <SidebarRow label="common.transaction.itemPriceGross">
          <MonetaryValue
            amount={parseFloat(item.itemPriceGross)}
            currency={currency}
          />
        </SidebarRow>
      )}
    </>
  );
};
