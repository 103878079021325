import React, { useMemo } from 'react';
import { DashboardPanel } from '../DashboardPanel';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { TotalCostPanelBody } from './TotalCostPanelBody';
import {
  useDashboardFilteredTransactions,
  getDashboardCurrencyFilter,
  useDashboardFilteredVehicles,
} from '../../../../state/transactions/transactionFilterSlice';
import { useAppSelector } from '../../../../state/hooks';
import { InfoPopup } from '../../../../components/InfoPopup';

export const TotalCostPanel: React.FC = () => {
  const { vehicles, isLoading: isLoadingVehicles } =
    useDashboardFilteredVehicles();
  const {
    filteredTransactions: transactions,
    isLoading: isLoadingTransactions,
  } = useDashboardFilteredTransactions();
  const currency = useAppSelector(getDashboardCurrencyFilter);

  const totalAmount = useMemo(() => {
    return transactions
      .map((t) => parseFloat(t.totalAmountGross || '0'))
      .reduce((a, b) => a + b, 0);
  }, [transactions]);

  const averagePerVehicle =
    vehicles.length === 0 ? 0 : totalAmount / vehicles.length;

  return (
    <DashboardPanel
      header={
        <div className={'display-flex align-items-center'}>
          <CustomFormattedMessage id={'dashboard.panels.totalCost.header'} />
          <div
            data-testid="dashboard-total-cost-information-icon-wrapper"
            className={'margin-left-5'}
          >
            <InfoPopup
              dataTestid="dashboard-total-cost-information-tooltip"
              marginTop={0}
              placement={'right'}
            >
              <CustomFormattedMessage
                id={'dashboard.panels.totalCost.informationTooltip'}
              />
            </InfoPopup>
          </div>
        </div>
      }
      testId="dashboard-total-costs-panel"
      body={
        <TotalCostPanelBody
          loading={isLoadingVehicles || isLoadingTransactions}
          averagePerVehicle={averagePerVehicle}
          currency={currency}
          totalAmount={totalAmount}
        />
      }
    />
  );
};
