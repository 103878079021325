import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OnboardingPage } from '../pages/onboarding/OnboardingPage';
import { RedirectPage } from '../pages/RedirectPage';
import { DashboardPage } from '../pages/dashboard/DashboardPage';
import { TransactionPage } from '../pages/transactions/TransactionsPage';
import { BookingsPage } from '../pages/bookings/BookingsPage';
import { LandingPage } from '../pages/landing/LandingPage';
import {
  BOOKINGS_ROUTE,
  DASHBOARD_ROUTE,
  LANDING_ROUTE,
  ONBOARDING_ROUTE,
  REDIRECT_FROM_AUTH_SERVER_ROUTE,
  TRANSACTIONS_ROUTE,
} from './routes';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={LANDING_ROUTE}
        element={<LandingPage />}
      />

      <Route
        path={ONBOARDING_ROUTE}
        element={<OnboardingPage />}
      />
      <Route
        path={DASHBOARD_ROUTE}
        element={<DashboardPage />}
      />
      <Route
        path={TRANSACTIONS_ROUTE}
        element={<TransactionPage />}
      />
      <Route
        path={BOOKINGS_ROUTE}
        element={<BookingsPage />}
      />
      <Route
        path={REDIRECT_FROM_AUTH_SERVER_ROUTE}
        element={<RedirectPage />}
      />
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={ONBOARDING_ROUTE}
          />
        }
      />
    </Routes>
  );
};
