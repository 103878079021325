import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { DialogMode } from '../../state/onboardingUiSlice';

type VehicleDialogFooterProps = {
  onClose: () => void;
  onSave: () => void;
  dialogMode?: DialogMode;
  saveEnabled?: boolean;
  showLegalText?: boolean;
  isLoading: boolean;
};

export const VehicleDialogFooter: React.FC<VehicleDialogFooterProps> = ({
  saveEnabled = true,
  showLegalText = false,
  dialogMode = DialogMode.SERVICES,
  isLoading,
  onClose,
  onSave,
}) => {
  const loadingClass = isLoading ? 'btn-loading' : '';
  return (
    <>
      {dialogMode === DialogMode.SERVICES ? (
        <>
          {showLegalText && (
            <div
              data-testid={'transactions-sidebar-amounts'}
              className="margin-bottom-20"
            >
              <CustomFormattedMessage id={'onboarding.dialog.services.legal'} />
            </div>
          )}
          <Button
            bsStyle={'default'}
            type={'button'}
            className={'margin-right-10'}
            onClick={onClose}
            disabled={isLoading}
          >
            <CustomFormattedMessage id={'common.action.cancel'} />
          </Button>
          <Button
            bsStyle={'primary'}
            type={'submit'}
            className={loadingClass}
            onClick={onSave}
            disabled={!saveEnabled || isLoading}
          >
            <CustomFormattedMessage id={'common.action.save'} />
          </Button>
        </>
      ) : (
        <>
          <Button
            bsStyle={'default'}
            type={'button'}
            className={'margin-right-10'}
            onClick={onClose}
            disabled={isLoading}
          >
            <CustomFormattedMessage id={'common.action.close'} />
          </Button>
        </>
      )}
    </>
  );
};
