import {
  PaymentMethodType,
  PaymentPartnerType,
} from '../../codegen/transactions';
import { ServiceCardTypeIcon } from '../icons/ServiceCardTypeIcon';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { PAYMENT_METHOD_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';

type PaymentMethodRepresentationProps = {
  paymentMethod: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
};

export const PaymentMethodRepresentation = ({
  paymentPartner,
  paymentMethod,
}: PaymentMethodRepresentationProps) => {
  return (
    <>
      <ServiceCardTypeIcon
        type={paymentPartner}
        className="height-20 margin-right-10"
      />
      <CustomFormattedMessage
        id={PAYMENT_METHOD_TO_TRANSLATION_KEY[paymentMethod]}
      />
    </>
  );
};
