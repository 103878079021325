import React from 'react';
import { CustomFormattedMessage } from '../i18n/i18n';
import NoData from '@rio-cloud/rio-uikit/lib/es/NoData';

export const NoDataPanelBody: React.FC = () => (
  <div className="text-center text-size-20 text-uppercase text-color-light">
    <span className="display-block rioglyph rioglyph-looking-glass-man text-size-400pct padding-15" />
    <NoData
      className={'text-size-20 display-block'}
      text={<CustomFormattedMessage id="common.noData" />}
    />
  </div>
);
