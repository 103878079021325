import { CustomFormattedDate } from '../../../../i18n/i18n';
import { TransactionStateLabel } from '../TransactionStateLabel';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { ServicePartnerRepresentation } from '../../../../components/dataRepresentation/ServicePartnerRepresentation';
import { TransactionWithVehicle } from '../../../../models';
import { SidebarRow } from '../../../../components/sidebar/SidebarRow';
import { PaymentMethodRepresentation } from '../../../../components/dataRepresentation/PaymentMethodRepresentation';

type TransactionDetailsTableProps = {
  transactionWithVehicle: TransactionWithVehicle;
};

export const TransactionSummaryTable = ({
  transactionWithVehicle,
}: TransactionDetailsTableProps) => {
  const { transaction, vehicleWithGroups } = transactionWithVehicle;
  if (!transaction || !vehicleWithGroups) {
    return <></>;
  }

  return (
    <table className="table table-condensed">
      <colgroup>
        <col style={{ width: 150 }} />
        <col />
      </colgroup>
      <tbody>
        <SidebarRow label="common.transaction.state">
          <TransactionStateLabel state={transaction.state} />
        </SidebarRow>
        <SidebarRow label="common.vehicle.vin">
          {vehicleWithGroups.vin}
        </SidebarRow>
        <SidebarRow label="transactions.transactionsTable.header.service">
          <ServiceRepresentation serviceType={transaction.serviceType} />
        </SidebarRow>
        <SidebarRow label="common.servicePartner">
          <ServicePartnerRepresentation
            servicePartner={transaction.servicePartner}
          />
        </SidebarRow>
        <SidebarRow label="common.transaction.createdAt">
          <CustomFormattedDate value={transaction.createdAt} />
        </SidebarRow>
        <SidebarRow
          label="common.transaction.lastUpdate"
          show={!!transaction.updatedAt}
        >
          <CustomFormattedDate value={transaction.updatedAt} />
        </SidebarRow>
        <SidebarRow
          label="common.transaction.paidAt"
          show={!!transaction.paymentDetails.paidAt}
        >
          <CustomFormattedDate value={transaction.paymentDetails.paidAt} />
        </SidebarRow>
        <SidebarRow label="common.paymentMethod">
          <PaymentMethodRepresentation
            paymentMethod={transaction.paymentDetails.paymentMethod}
            paymentPartner={transaction.paymentDetails.paymentPartner}
          />
        </SidebarRow>
        <SidebarRow label="common.transaction.mileage">
          {transaction.mileage}
        </SidebarRow>
        <SidebarRow label="common.transaction.id">{transaction.id}</SidebarRow>
      </tbody>
    </table>
  );
};
