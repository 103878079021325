import React from 'react';
import { BookingsPageContent } from './components/BookingsPageContent';
import { useVehicles } from '../../state/vehicles/useVehicles';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useTableFilteredBookings } from '../../state/bookings/bookingsFilterSlice';

export const BookingsPage: React.FC = () => {
  const { isLoading: isVehiclesLoading, error: errorVehicles } = useVehicles();
  const { isLoading: isBookingsLoading, error: errorBookings } =
    useTableFilteredBookings();

  if (errorBookings || errorVehicles) {
    return <NoDataPlaceholder />;
  }

  if (isBookingsLoading || isVehiclesLoading) {
    return <Spinner isDoubleSized />;
  }

  return <BookingsPageContent />;
};
