import {
  enhancedApi,
  GetTransactionsApiArg,
  GetTransactionsApiResponse,
} from '../../codegen/transactions';
import { PartialPick } from '../../models';
import { isUndefined } from 'lodash';

const DEFAULT_TRANSACTION_PAGE_SIZE = 1000;
type PaginationProps = 'pageNumber' | 'pageSize';
const TRANSACTION_URL = '/transactions';

export type GetTransactionsRequestParams = PartialPick<
  GetTransactionsApiArg,
  PaginationProps
>;

export const injectedTransactionApiEndpoints = enhancedApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactionsPaginated: build.query<
      GetTransactionsApiResponse,
      GetTransactionsRequestParams
    >({
      queryFn: async (queryArg, _queryApi, _extraOptions, fetchWithBQ) => {
        /* If the `pageSize` and `pageNumber` are provided, use the standard query approach. */
        if (
          !isUndefined(queryArg.pageSize) &&
          !isUndefined(queryArg.pageNumber)
        ) {
          return fetchWithBQ({
            url: TRANSACTION_URL,
            params: {
              pageSize: queryArg.pageSize,
              pageNumber: queryArg.pageNumber,
              vehicleId: queryArg.vehicleId,
              vehicleGroupId: queryArg.vehicleGroupId,
              serviceType: queryArg.serviceType,
              currency: queryArg.currency,
              fromCreatedAt: queryArg.fromCreatedAt,
              toCreatedAt: queryArg.toCreatedAt,
            },
          });
        }

        /* If the `pageSize` and `pageNumber` are not provided, trigger multiple paginated queries. */
        return iterateThroughAllPages(fetchWithBQ, TRANSACTION_URL, queryArg);
      },
    }),
  }),
});

const iterateThroughAllPages = async (
  fetchWithBQ: (arg: Parameters<any>[0]) => ReturnType<any>,
  url: string,
  requestParams: GetTransactionsRequestParams,
) => {
  const aggregatedResponse: GetTransactionsApiResponse = {
    items: [],
    totalItems: 0,
    totalPages: 1,
  };
  let totalPages = 1;

  for (let currentPage = 0; currentPage < totalPages; currentPage++) {
    const pageResult = await fetchWithBQ({
      url,
      params: {
        pageSize: DEFAULT_TRANSACTION_PAGE_SIZE,
        pageNumber: currentPage,
        vehicleId: requestParams.vehicleId,
        vehicleGroupId: requestParams.vehicleGroupId,
        serviceType: requestParams.serviceType,
        currency: requestParams.currency,
        fromCreatedAt: requestParams.fromCreatedAt,
        toCreatedAt: requestParams.toCreatedAt,
      },
    });

    if (pageResult.error) {
      return { error: pageResult.error };
    }

    const pageData = pageResult.data;
    aggregatedResponse.items.push(...pageData.items);
    aggregatedResponse.totalItems = aggregatedResponse.items.length;

    if (currentPage === 0) {
      totalPages = pageData.totalPages;
    }
  }

  return { data: aggregatedResponse };
};
