import { useCustomIntl } from '../i18n/i18n';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

type NoDataPlaceholderProps = {
  className?: string;
};

export const NoDataPlaceholder = ({ className }: NoDataPlaceholderProps) => {
  const intl = useCustomIntl();

  return (
    <NotFoundState
      headline={intl.formatMessage({
        id: 'common.noDataFound.headline',
      })}
      message={intl.formatMessage({ id: 'common.noDataFound.message' })}
      outerClassName={className}
    />
  );
};
