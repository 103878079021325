import { getDatesForPeriod, Period } from '../../usePeriodSelectOptions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../store';
import { useBookings } from './useBookings';
import { useAppSelector } from '../hooks';
import { BookingStatus } from '../../codegen/bookings';

type BookingsFilterState = {
  bookingsStatus: BookingStatus[];
  from: number;
  to: number;
  period?: Period;
  page: number;
};

const FIRST_PAGE = 1;
const DEFAULT_PERIOD = Period.NEXT_7_DAYS;

const initialState: BookingsFilterState = {
  bookingsStatus: [],
  from: getDatesForPeriod(DEFAULT_PERIOD).from.valueOf(),
  to: getDatesForPeriod(DEFAULT_PERIOD).to.valueOf(),
  period: DEFAULT_PERIOD,
  page: FIRST_PAGE,
};

export const bookingsFilterSlice = createSlice({
  name: 'bookingsFilter',
  initialState,
  reducers: {
    setBookingsStatus: (state, action: PayloadAction<BookingStatus[]>) => {
      state.bookingsStatus = action.payload;

      state.page = FIRST_PAGE;
    },
    setFrom: (state, action: PayloadAction<number>) => {
      state.from = moment(action.payload).startOf('day').valueOf();
      state.period = undefined;

      state.page = FIRST_PAGE;
    },
    setTo: (state, action: PayloadAction<number>) => {
      state.to = moment(action.payload).endOf('day').valueOf();
      state.period = undefined;

      state.page = FIRST_PAGE;
    },
    setPeriod: (state, action: PayloadAction<Period | undefined>) => {
      const period = action.payload;
      state.period = period;

      if (period) {
        const dates = getDatesForPeriod(period);
        state.from = dates.from.startOf('day').valueOf();
        state.to = dates.to.endOf('day').valueOf();

        state.page = FIRST_PAGE;
      }
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetBookingsFilter: (state) => {
      state.bookingsStatus = initialState.bookingsStatus;
      state.from = initialState.from;
      state.to = initialState.to;
      state.period = initialState.period;
      state.page = FIRST_PAGE;
    },
  },
});

export const {
  setBookingsStatus,
  setFrom,
  setTo,
  setPeriod,
  setPage,
  resetBookingsFilter,
} = bookingsFilterSlice.actions;

export const getBookingsStatusFilter = (state: RootState) =>
  state.bookingsFilter.bookingsStatus;
export const getFromFilter = (state: RootState) => state.bookingsFilter.from;
export const getToFilter = (state: RootState) => state.bookingsFilter.to;
export const getPeriodFilter = (state: RootState) =>
  state.bookingsFilter.period;
export const getBookingsPage = (state: RootState) => state.bookingsFilter.page;

export const BOOKING_TABLE_PAGE_SIZE = 50;

export const useTableFilteredBookings = () => {
  const bookingsStatus = useAppSelector(getBookingsStatusFilter);
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const page = useAppSelector(getBookingsPage);

  const {
    bookings,
    totalBookings,
    isLoading,
    isFetching,
    error,
    findBookingWithId,
  } = useBookings({
    status: bookingsStatus,
    pageSize: BOOKING_TABLE_PAGE_SIZE,
    pageNumber: page - 1,
    arrivalBefore: moment(to).format(),
    arrivalAfter: moment(from).format(),
  });

  return {
    filteredBookings: bookings,
    totalBookings,
    isLoading,
    isFetching,
    error,
    findBookingWithId,
  };
};
