import { ReactElement } from 'react';

type PaginationProps = {
  currentPage: number;
  numberOfPages: number;
  onPageChanged: (newPage: number) => void;
};

type PageButtonProps = {
  page?: number;
  active: boolean;
  onClick: () => void;
};

const range = (start: number, stop: number) => {
  const rangeArray = [];
  for (let i = start; i < stop; i++) {
    rangeArray.push(i);
  }
  return rangeArray;
};

const PageButton = ({
  page,
  active,
  onClick,
}: PageButtonProps): ReactElement => {
  return (
    <li
      className={`${active ? 'active' : ''} ${
        page === undefined ? 'disabled' : ''
      }`}
      onClick={onClick}
    >
      <span>{page !== undefined ? page : '...'}</span>
    </li>
  );
};

export const Pagination = ({
  currentPage,
  numberOfPages,
  onPageChanged,
}: PaginationProps): ReactElement => {
  if (numberOfPages <= 1) {
    return <></>;
  }

  const definePages = (): (number | undefined)[] => {
    if (numberOfPages <= 5) {
      return range(1, numberOfPages + 1);
    } else if (currentPage <= 3) {
      const pages: (number | undefined)[] = range(1, 5);
      pages.push(undefined);
      pages.push(numberOfPages);
      return pages;
    } else if (currentPage >= numberOfPages - 2) {
      const pages = [];
      pages.push(1);
      pages.push(undefined);
      pages.push(...range(numberOfPages - 3, numberOfPages + 1));
      return pages;
    } else {
      const pages = [];
      pages.push(1);
      pages.push(undefined);
      pages.push(...range(currentPage - 1, currentPage + 2));
      pages.push(undefined);
      pages.push(numberOfPages);
      return pages;
    }
  };

  const createPageButtons = () => {
    const pages = definePages();
    return pages.map((page, index) => (
      <PageButton
        key={index}
        page={page}
        active={currentPage === page}
        onClick={() => page && onPageChanged(page)}
      />
    ));
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numberOfPages;

  return (
    <div role="pagination">
      <ul className="pagination bg-white margin-top-4">
        <li
          data-testid={'pagination-previous-page-button'}
          className={isFirstPage ? 'disabled' : ''}
          onClick={() => !isFirstPage && onPageChanged(currentPage - 1)}
        >
          <span className="rioglyph rioglyph-chevron-left" />
        </li>
        {createPageButtons()}
        <li
          data-testid={'pagination-next-page-button'}
          className={isLastPage ? 'disabled' : ''}
          onClick={() => !isLastPage && onPageChanged(currentPage + 1)}
        >
          <span className="rioglyph rioglyph-chevron-right" />
        </li>
      </ul>
    </div>
  );
};
