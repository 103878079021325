import React from 'react';
import { VehicleIcon } from '../../../components/icons/VehicleIcon';
import { Vehicle } from '../../../codegen/vehicles';

type VehicleHeadlineProps = {
  vehicle: Vehicle;
};

export const VehicleHeadline: React.FC<VehicleHeadlineProps> = ({
  vehicle,
}) => {
  return (
    <>
      <h5>
        <span className="text-color-dark white-space-nowrap">
          <VehicleIcon
            type={vehicle.type}
            className="margin-right-5"
          />
          <span className="margin-right-5">{vehicle.name}</span>
        </span>
      </h5>
      <div className={'text-color-dark margin-top--10'}>{vehicle.vin}</div>
    </>
  );
};
