import React, { ReactNode } from 'react';

export const Tag: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <span
      className={
        'border text-center rounded text-color-dark bg-lightest padding-left-10 padding-right-10 margin-right-5'
      }
    >
      {children}
    </span>
  );
};
