import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import { SortDirection } from '../../models';

export type HeaderCellSorting<T> = {
  sortBy?: keyof T;
  sortDirection: SortDirection;
  onSortClick: (sortDirection: SortDirection) => void;
};

type SortableTableHeaderCellProps<T> = {
  id: keyof T;
  label: string;
  sorting?: HeaderCellSorting<T>;
};

export const TableHeaderCell = <T extends unknown>({
  id,
  label,
  sorting,
}: SortableTableHeaderCellProps<T>) => {
  const handleSortChange = () => {
    if (sorting) {
      const direction = nextSortDirection();
      sorting.onSortClick(direction);
    }
  };

  const nextSortDirection = (): SortDirection => {
    if (id === sorting?.sortBy) {
      return sorting.sortDirection === SortDirection.ASCENDING
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
  };

  const getSortIcon = () => {
    if (!sorting) {
      return <></>;
    }

    return sorting.sortBy === id ? (
      <SortArrows direction={sorting.sortDirection} />
    ) : (
      <SortArrows />
    );
  };

  return (
    <th
      className={`user-select-none ${sorting && 'sort-column'}`}
      onClick={handleSortChange}
      data-field={id}
      data-sortby={id}
      title={label}
    >
      <span>
        {getSortIcon()}
        <span>{label}</span>
      </span>
    </th>
  );
};
