import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useCustomIntl } from '../../../../i18n/i18n';
import { useAppSelector } from '../../../../state/hooks';
import { isBookingDialogOpen } from '../../state/bookingsUiSlice';
import { CancelDialogBody } from './CancelDialogBody';
import { CancelDialogFooter } from './CancelDialogFooter';
import { useCancelSelectedBooking } from '../../../../state/bookings/useCancelSelectedBooking';

export const CancelDialog = () => {
  const intl = useCustomIntl();

  const { isLoading, error, isSuccess, cancelParkingBooking } =
    useCancelSelectedBooking();

  const isDialogueOpen = useAppSelector(isBookingDialogOpen);

  return (
    <Dialog
      data-testid={'booking-cancel-dialog'}
      show={isDialogueOpen}
      title={intl.formatMessage({
        id: 'common.booking.cancel.dialog.confirmation.title',
      })}
      body={
        <div data-testid="booking-cancel-dialog-body">
          <CancelDialogBody
            isSuccess={isSuccess}
            isError={!!error}
          />
        </div>
      }
      footer={
        <CancelDialogFooter
          isError={!!error}
          isSuccess={isSuccess}
          isLoading={isLoading}
          onCancel={cancelParkingBooking}
        />
      }
      showCloseButton={false}
      bsSize="md"
    />
  );
};
