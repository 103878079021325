import React from 'react';
import fuelingLogo from '../../assets/images/partners/fueling-placeholder.png';
import breakdownLogo from '../../assets/images/partners/breakdown-placeholder.png';
import roadSideLogo from '../../assets/images/partners/roadside-placeholder.png';
import mobile24Logo from '../../assets/images/partners/man-mobile24-logo.svg';
import travisLogo from '../../assets/images/partners/travis-logo.png';
import utaLogo from '../../assets/images/partners/uta-logo.png';
import { ServicePartner } from '../../codegen/transactions';

type ServicePartnerIconProps = {
  servicePartner: ServicePartner;
  className?: string;
};

const servicePartnersToIcon: { [key in ServicePartner]: string } = {
  MOCK_FUELING_PARTNER: fuelingLogo,
  MOCK_BREAKDOWN_PARTNER: breakdownLogo,
  MOCK_ROADSIDE_PARTNER: roadSideLogo,
  MOBILE_24: mobile24Logo,
  TRAVIS: travisLogo,
  UTA: utaLogo,
};

export const ServicePartnerIcon: React.FC<ServicePartnerIconProps> = ({
  servicePartner,
  className,
}) => (
  <img
    src={servicePartnersToIcon[servicePartner]}
    className={className}
  />
);
