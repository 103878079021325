import React from 'react';
import { Address } from '../codegen/transactions';

export const FormattedAddress: React.FC<Address> = ({
  street,
  zipCode,
  city,
  country,
  state,
}) => (
  <>
    <div>{street}</div>
    <div>
      {zipCode} {city}
    </div>
    {state && <div>{state}</div>}
    <div>{country}</div>
  </>
);
