import {
  BookingStatus,
  ListBookingsApiArg,
  useListBookingsQuery,
} from '../../codegen/bookings';
import { BOOKING_STATUS_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { TranslationsKeys, useCustomIntl } from '../../i18n/i18n';
import { useVehicles } from '../vehicles/useVehicles';
import { useMemo } from 'react';

export const useBookings = (request: ListBookingsApiArg) => {
  const vehiclesQuery = useVehicles();
  const bookingsQuery = useListBookingsQuery(request);

  const bookingItems = bookingsQuery.data?.items || [];

  const bookings = useMemo(
    () =>
      bookingItems.map((booking) => {
        const vehicle = vehiclesQuery.vehicles.find(
          (v) => v.id === booking.vehicleInfo.vehicleId,
        );
        return {
          ...booking,
          vehicle,
        };
      }),
    [vehiclesQuery.vehicles, bookingItems],
  );

  const findBookingWithId = (id: string) => {
    return bookings.find((b) => b.id === id);
  };

  return {
    bookings,
    totalBookings: bookingsQuery.data?.totalItems || 0,
    isLoading: bookingsQuery.isLoading || vehiclesQuery.isLoading,
    isFetching: bookingsQuery.isFetching || vehiclesQuery.isFetching,
    error: bookingsQuery.error || vehiclesQuery.error,
    findBookingWithId,
  };
};

export const useStatusOptions = () => {
  const intl = useCustomIntl();

  const getLabel = (id: TranslationsKeys) => intl.formatMessage({ id });

  const status: BookingStatus[] = [
    BookingStatus.Planned,
    BookingStatus.InProgress,
    BookingStatus.Canceled,
    BookingStatus.Completed,
  ];

  return status.map((s) => ({
    id: s,
    label: getLabel(BOOKING_STATUS_TO_TRANSLATION_KEY[s]),
  }));
};
