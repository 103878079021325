import React from 'react';
import { VehicleInformationPanel } from './components/VehicleInformationPanel';
import { OperationalCostPanel } from './components/operationalCostsPanel/OperationalCostPanel';
import { TotalCostPanel } from './components/totalCostsPanel/TotalCostPanel';
import { FilterBar } from './components/FilterBar';
import {
  Top5CostsPanel,
  Top5CostsPanelType,
} from './components/top5CostsPanel/Top5CostsPanel';
import {
  // Lint: Force line break (max-len = 120)
  TransactionCountPerServicePanel,
} from './components/transactionCountPerServicePanel/TransactionCountPerServicePanel';
import { useHandleTransactionUpdateNotification } from '../../components/useHandleTransactionUpdateNotification';
import { CostsPerServiceOverTimePanel } from './components/costsPerServiceOverTimePanel/CostsPerServiceOverTimePanel';

export const DashboardPage: React.FC = () => {
  useHandleTransactionUpdateNotification();

  return (
    <div className="container">
      <FilterBar />
      <div className="display-flex justify-content-center text-color-dark flex-wrap gap-15">
        <VehicleInformationPanel />
        <TotalCostPanel />
        <OperationalCostPanel />
        <Top5CostsPanel type={Top5CostsPanelType.LOWEST} />
        <Top5CostsPanel type={Top5CostsPanelType.HIGHEST} />
        <TransactionCountPerServicePanel />
        <CostsPerServiceOverTimePanel />
      </div>
    </div>
  );
};
