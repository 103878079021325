import React from 'react';
import { ActiveService } from './ActiveService';
import {
  PaymentMethod,
  ServiceActivation,
} from '../../../../../codegen/vehicles';

export type ActiveServicesTableProps = {
  activeServices: ServiceActivation[];
  paymentMethods: PaymentMethod[];
};

export const ActiveServicesTable: React.FC<ActiveServicesTableProps> = ({
  activeServices,
  paymentMethods,
}) => {
  return (
    <>
      {activeServices
        .filter((service) => service.enabled)
        .map((service, index) => (
          <div
            key={index}
            className={'padding-bottom-10 padding-top-10'}
            data-testid={`onboarding-vehicle-sidebar-service-${service.serviceType}`}
          >
            <table className="table table-condensed">
              <tbody>
                <ActiveService
                  activeService={service}
                  paymentMethods={paymentMethods}
                />
              </tbody>
            </table>
          </div>
        ))}
    </>
  );
};
