import { BookingWithVehicle } from '../../../../models';
import { BookingStatusLabel } from '../BookingStatusLabel';
import {
  CustomFormattedDate,
  CustomFormattedMessage,
} from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { SidebarRow } from '../../../../components/sidebar/SidebarRow';
import { useLocations } from '../../../../state/locations/useLocations';
import { FormattedAddress } from '../../../../components/FormattedAddress';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

type BookingDetailsTableProps = {
  booking: BookingWithVehicle;
};

export const BookingDetailsTable = ({ booking }: BookingDetailsTableProps) => {
  const { location, isLoading, error } = useLocations(
    booking.locationId,
    booking.serviceType,
  );
  const serviceLocation = location?.address;

  const getServiceLocation = () => {
    if (isLoading) {
      return <ContentLoader data-testid={'content-loader'} />;
    }

    if (error || !serviceLocation) {
      return (
        <CustomFormattedMessage id="bookings.sidebar.serviceLocation.unknown" />
      );
    }

    return (
      <FormattedAddress
        street={serviceLocation.street}
        zipCode={serviceLocation.zipCode}
        city={serviceLocation.city}
        country={serviceLocation.country}
      />
    );
  };

  if (!booking || !booking.vehicle) {
    return <></>;
  }

  return (
    <table className={'table table-condensed'}>
      <colgroup>
        <col style={{ width: 150 }} />
        <col />
      </colgroup>
      <tbody>
        <SidebarRow label="common.booking.id">{booking.id}</SidebarRow>
        <SidebarRow label="common.booking.serviceType">
          <ServiceRepresentation serviceType={booking.serviceType} />
        </SidebarRow>
        <SidebarRow label="common.booking.bookingStatus">
          <BookingStatusLabel status={booking.status} />
        </SidebarRow>
        <SidebarRow label="common.booking.serviceLocation">
          {getServiceLocation()}
        </SidebarRow>
        <SidebarRow label="common.booking.arrival">
          <CustomFormattedDate value={booking.arrivalTime} />
        </SidebarRow>
        <SidebarRow label="common.booking.departure">
          <CustomFormattedDate value={booking.departureTime} />
        </SidebarRow>
        <SidebarRow label="common.booking.estimatedPrice">
          <MonetaryValue
            amount={parseFloat(booking.estimatedPrice.value)}
            currency={booking.estimatedPrice.currency}
          />
        </SidebarRow>
        {booking.entryInstructions.instructions && (
          <SidebarRow label="common.booking.entryInstructions">
            {booking.entryInstructions.instructions}
          </SidebarRow>
        )}
        {booking.entryInstructions.entryCode && (
          <SidebarRow label="common.booking.entryCode">
            {booking.entryInstructions.entryCode}
          </SidebarRow>
        )}
        {booking.entryInstructions.reportAt && (
          <SidebarRow label="common.booking.reportAt">
            {booking.entryInstructions.reportAt}
          </SidebarRow>
        )}
        {booking.entryInstructions.entryLink && (
          <SidebarRow label="common.booking.entryLink">
            {booking.entryInstructions.entryLink}
          </SidebarRow>
        )}
        {booking.entryInstructions.locationReservationNumber && (
          <SidebarRow label="common.booking.locationReservationNumber">
            {booking.entryInstructions.locationReservationNumber}
          </SidebarRow>
        )}
        <SidebarRow label="common.booking.driver">
          {booking.driverName}
        </SidebarRow>
        {booking.trailerInfo?.licensePlate && (
          <SidebarRow label="common.booking.trailer">
            {booking.trailerInfo.licensePlate}
          </SidebarRow>
        )}
      </tbody>
    </table>
  );
};
