import { FeatureTeaser } from '../FeatureTeaser';
import driverAppImage from '../../../assets/images/landingPage/driver-app.svg';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useAppSelector } from '../../../state/hooks';
import { getLocale } from '../../../i18n/localeSlice';

const LOCALE_GERMAN = 'de-DE';

export const DriverAppTeaser = () => {
  const userLocale = useAppSelector(getLocale);

  return (
    <FeatureTeaser
      image={driverAppImage}
      headline={
        <CustomFormattedMessage
          id={'landing.partnerTeaser.driverApp.headline'}
        />
      }
      content={
        <CustomFormattedMessage id={'landing.partnerTeaser.driverApp.body'} />
      }
      onClick={() => {
        const link =
          userLocale === LOCALE_GERMAN
            ? 'https://www.man.eu/de/de/service/man-driver-app-103744.html'
            : 'https://www.man.eu/uk/en/service/man-driver-app-103744.html';

        window.open(link, '_blank', 'noopener');
      }}
    />
  );
};
