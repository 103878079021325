import { useMemo } from 'react';
import {
  getDashboardCurrencyFilter,
  useDashboardFilteredTransactions,
  useDashboardFilteredVehicles,
} from '../../../../state/transactions/transactionFilterSlice';
import { useAppSelector } from '../../../../state/hooks';
import { Transaction } from '../../../../codegen/transactions';
import { VehicleWithGroups } from '../../../../models';

const NUMBER_OF_TOP_ENTRIES = 5;

type VehicleIdTotal = {
  vehicleId: string;
  totalAmount: number;
};
export type VehicleTotal = {
  vehicle: VehicleWithGroups;
  totalAmount: number;
};

const sortAscending = (a: VehicleIdTotal, b: VehicleIdTotal) =>
  a.totalAmount - b.totalAmount;

const addVehicleInfo = (vehicles: VehicleWithGroups[]) => {
  return (vehicleIdTotal: VehicleIdTotal) => ({
    vehicle: vehicles.filter(
      (vehicle: VehicleWithGroups) => vehicle.id === vehicleIdTotal.vehicleId,
    )[0],
    totalAmount: vehicleIdTotal.totalAmount,
  });
};

export const useTop5CostsPanel = () => {
  const {
    vehicles,
    isLoading: isLoadingVehicles,
    error: errorLoadingVehicles,
  } = useDashboardFilteredVehicles();
  const {
    filteredTransactions: transactions,
    isLoading: isLoadingTransactions,
    error: errorLoadingTransactions,
  } = useDashboardFilteredTransactions();
  const currency = useAppSelector(getDashboardCurrencyFilter);

  const sortedVehicleIds = useMemo<VehicleIdTotal[]>(() => {
    const totalByVehicleIdMap: Map<string, number> = transactions.reduce(
      (map, transaction: Transaction) => {
        const previousVehicleTotal: number =
          map.get(transaction.vehicleId) ?? 0;
        map.set(
          transaction.vehicleId,
          previousVehicleTotal + Number(transaction.totalAmountGross),
        );
        return map;
      },
      new Map<string, number>(),
    );

    const totalByVehicleId: VehicleIdTotal[] = Array.from(
      totalByVehicleIdMap,
    ).map(([vehicleId, totalAmount]: [string, number]) => ({
      vehicleId,
      totalAmount,
    }));

    return totalByVehicleId
      .filter(
        (vehicleIdTotal: VehicleIdTotal) => vehicleIdTotal.totalAmount > 0,
      )
      .sort(sortAscending);
  }, [transactions]);

  const top5LowestCostVehicles = useMemo<VehicleTotal[]>(() => {
    const top5TotalByVehicleId: VehicleIdTotal[] = sortedVehicleIds.slice(
      0,
      NUMBER_OF_TOP_ENTRIES,
    );
    return top5TotalByVehicleId.map(addVehicleInfo(vehicles));
  }, [vehicles, sortedVehicleIds]);

  const top5HighestCostVehicles = useMemo<VehicleTotal[]>(() => {
    const top5TotalByVehicleId: VehicleIdTotal[] = sortedVehicleIds
      .slice(-1 * NUMBER_OF_TOP_ENTRIES)
      .reverse();
    return top5TotalByVehicleId.map(addVehicleInfo(vehicles));
  }, [vehicles, sortedVehicleIds]);

  const sameTop5Vehicles = sortedVehicleIds.length <= NUMBER_OF_TOP_ENTRIES;

  const noData =
    errorLoadingVehicles ||
    errorLoadingTransactions ||
    vehicles.length === 0 ||
    transactions.length === 0;

  return {
    top5LowestCostVehicles,
    top5HighestCostVehicles,
    sameTop5Vehicles,
    currency,
    isLoading: isLoadingVehicles || isLoadingTransactions,
    error: errorLoadingVehicles || errorLoadingTransactions,
    noData,
  };
};
