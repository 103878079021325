import { VehicleWithGroups } from '../../models';
import React from 'react';
import { VehicleIcon } from '../icons/VehicleIcon';

type SidebarVehicleHeadlineProps = {
  vehicleWithGroups: VehicleWithGroups;
};

export const SidebarVehicleHeadline: React.FC<SidebarVehicleHeadlineProps> = ({
  vehicleWithGroups,
}) => {
  return (
    <h5>
      <span className={'text-color-dark white-space-nowrap'}>
        <VehicleIcon
          type={vehicleWithGroups.type}
          className={'margin-right-5'}
        />
        <span className={'margin-right-5'}>{vehicleWithGroups.name}</span>
      </span>
    </h5>
  );
};
