import { CustomFormattedMessage } from '../../i18n/i18n';
import { FUEL_CARD_TYPE_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { PaymentPartnerType } from '../../codegen/vehicles';
import { ServiceCardTypeIcon } from '../icons/ServiceCardTypeIcon';

export const ServiceCardRepresentation = ({
  paymentPartner,
}: {
  paymentPartner: PaymentPartnerType;
}) => {
  return (
    <div className="width-120">
      <ServiceCardTypeIcon
        type={paymentPartner}
        className="height-20 margin-right-10 margin-bottom-0"
      />
      <CustomFormattedMessage
        id={FUEL_CARD_TYPE_TO_TRANSLATION_KEY[paymentPartner]}
      />
    </div>
  );
};
