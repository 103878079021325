import { ReactNode, useEffect } from 'react';
import { useTransactionUpdateState } from '../state/transactions/transactionUpdateSlice';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import { useTableFilteredTransactions } from '../state/transactions/transactionFilterSlice';

const NOTIFICATION_TIMEOUT = 999999;

const notifyInfoDismissible = (message: ReactNode, onDismiss?: () => void) => {
  Notification.info(
    <span data-testid="dismissible-info-notification">
      {message}
      <span className="notification-close">
        <span className="rioglyph rioglyph-remove" />
      </span>
    </span>,
    undefined,
    NOTIFICATION_TIMEOUT,
    onDismiss,
  );
};

export const useHandleTransactionUpdateNotification = () => {
  const {
    hasTransactionUpdate,
    needToNotify,
    setNeedToNotify,
    reset: resetTransactionUpdateState,
  } = useTransactionUpdateState();
  const { resetApiState } = useTableFilteredTransactions();

  const handleRefreshTransactions = () => {
    resetApiState();
    resetTransactionUpdateState();
  };

  useEffect(() => {
    if (hasTransactionUpdate && needToNotify) {
      const formattedMessage = (
        <FormattedMessage
          id="transactions.notification.hasUpdate"
          values={{
            link: (parts) => (
              <span
                className="text-decoration-underline"
                onClick={handleRefreshTransactions}
              >
                {parts}
              </span>
            ),
          }}
        />
      );

      notifyInfoDismissible(formattedMessage);
      setNeedToNotify(false);
    }
  }, [hasTransactionUpdate, needToNotify]);
};
