import { transactionsApiTemplateSlice as api } from '../state/transactions/transactionsApiTemplateSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<
      GetTransactionsApiResponse,
      GetTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/transactions`,
        params: {
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          vehicleId: queryArg.vehicleId,
          vehicleGroupId: queryArg.vehicleGroupId,
          serviceType: queryArg.serviceType,
          currency: queryArg.currency,
          fromCreatedAt: queryArg.fromCreatedAt,
          toCreatedAt: queryArg.toCreatedAt,
        },
      }),
    }),
    getTransactionById: build.query<
      GetTransactionByIdApiResponse,
      GetTransactionByIdApiArg
    >({
      query: (queryArg) => ({ url: `/transactions/${queryArg.transactionId}` }),
    }),
    postFuelingTransactions: build.mutation<
      PostFuelingTransactionsApiResponse,
      PostFuelingTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/fueling/transactions`,
        method: 'POST',
        body: queryArg.startFuelingTransactionRequest,
      }),
    }),
    patchFuelingTransaction: build.mutation<
      PatchFuelingTransactionApiResponse,
      PatchFuelingTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/fueling/transactions/${queryArg.transactionId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetTransactionsApiResponse = /** status 200 OK */ TransactionsList;
export type GetTransactionsApiArg = {
  pageSize: number;
  pageNumber: number;
  vehicleId?: string[];
  vehicleGroupId?: string[];
  serviceType?: ServiceType[];
  currency?: Currency[];
  fromCreatedAt?: string;
  toCreatedAt?: string;
};
export type GetTransactionByIdApiResponse = /** status 200 OK */ Transaction;
export type GetTransactionByIdApiArg = {
  transactionId: string;
};
export type PostFuelingTransactionsApiResponse =
  /** status 201 Created */ Transaction;
export type PostFuelingTransactionsApiArg = {
  startFuelingTransactionRequest: StartFuelingTransactionRequest;
};
export type PatchFuelingTransactionApiResponse =
  /** status 200 OK */ Transaction;
export type PatchFuelingTransactionApiArg = {
  transactionId: string;
  body: CancelFuelingTransactionRequest;
};
export type PageResponse = {
  totalPages: number;
  totalItems: number;
};
export type Address = {
  street: string;
  zipCode: string;
  city: string;
  state?: string;
  /** A 2-digit country code, conforming to ISO 3166 Alpha-2 notation */
  country: string;
};
export type Location = {
  /** Reference to the Point of Interest from SimplePay's PoiService */
  id?: string;
  name: string;
  brand?: string;
  address: Address;
};
export type FuelingDetails = {
  pumpNumber: string;
};
export type PaymentDetails = {
  paymentMethod: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  paidAt?: string;
  lastFourDigits: string;
};
export type GeoCoordinates = {
  latitude: string;
  longitude: string;
};
export type VehicleDetails = {
  id: string;
  driverId?: string;
  /** Current mileage of the vehicle */
  mileage?: string;
  /** Position of the vehicle when transaction was started */
  vehicleLocation?: GeoCoordinates;
};
export type MonetaryValue = string;
export type Currency = string;
export type TransactionAmount = {
  amountNet: MonetaryValue;
  amountGross: MonetaryValue;
  amountVat: MonetaryValue;
  currency: Currency;
};
export type TransactionItem = {
  description: string;
  quantity: string;
  unit: UnitType;
  deliveryUnitAmount: TransactionAmount;
  deliveryAmount: TransactionAmount;
  dashboardAmount: TransactionAmount;
  completionAmount?: TransactionAmount;
  vatRate: string;
  itemPriceNet: MonetaryValue & any;
  itemPriceGross: MonetaryValue & any;
  sumNet: MonetaryValue & any;
  sumGross: MonetaryValue & any;
  vat: string;
};
export type Transaction = {
  id: string;
  referenceId?: string;
  serviceType: ServiceType;
  servicePartner: ServicePartner;
  state: TransactionState;
  cancelationState?: TransactionCancelationState;
  errorCode?: TransactionErrorCode;
  locationDetails?: Location;
  fuelingDetails?: FuelingDetails;
  paymentDetails: PaymentDetails;
  vehicleDetails: VehicleDetails;
  deliveryAmount?: TransactionAmount;
  completionAmount?: TransactionAmount;
  dashboardAmount?: TransactionAmount;
  items: TransactionItem[];
  /** Timestamp when transaction was delivered. The transaction state changed to DELIVERED. */
  deliveredAt?: string;
  /** Timestamp when transaction was completed. The transaction state changed to COMPLETED. */
  completedAt?: string;
  /** Timestamp when transaction was created. */
  createdAt: string;
  /** Timestamp when transaction was updated. */
  updatedAt?: string;
  reference_id?: string;
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  /** Mileage of the associated vehicle */
  mileage?: number;
  currency?: Currency & any;
  poi?: Location & any;
  pumpNumber?: number;
  invoiceNumber?: string;
  totalAmountGross?: MonetaryValue & any;
  totalAmountNet?: MonetaryValue & any;
};
export type TransactionsList = PageResponse & {
  items: Transaction[];
};
export type ErrorResponse = {
  message: string;
  errorCode: ErrorCode;
  fields: string[];
};
export type StartFuelingTransactionRequest = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  driverId?: string;
  /** Current mileage of the vehicle */
  mileage: string;
  /** Current position of the vehicle */
  vehicleLocation: GeoCoordinates;
  /** ID of a POI to call requests to SimplePay PoiService */
  poiId: string;
  pumpNumber: number;
};
export type CancelFuelingTransactionRequest = {
  status: Status;
};
export enum ServiceType {
  Fueling = 'FUELING',
  Breakdown = 'BREAKDOWN',
  Parking = 'PARKING',
  Washing = 'WASHING',
}
export enum ServicePartner {
  MockFuelingPartner = 'MOCK_FUELING_PARTNER',
  MockBreakdownPartner = 'MOCK_BREAKDOWN_PARTNER',
  MockRoadsidePartner = 'MOCK_ROADSIDE_PARTNER',
  Mobile24 = 'MOBILE_24',
  Travis = 'TRAVIS',
  Uta = 'UTA',
}
export enum TransactionState {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Delivered = 'DELIVERED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
}
export enum TransactionCancelationState {
  Triggered = 'TRIGGERED',
  Requested = 'REQUESTED',
  NotPossible = 'NOT_POSSIBLE',
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL',
}
export enum TransactionErrorCode {
  SelectionNotAvailable = 'SELECTION_NOT_AVAILABLE',
  PreAuthorizationFailed = 'PRE_AUTHORIZATION_FAILED',
  TransactionFailed = 'TRANSACTION_FAILED',
  TransactionNotPossible = 'TRANSACTION_NOT_POSSIBLE',
  InvalidPaymentMethod = 'INVALID_PAYMENT_METHOD',
  UnknownError = 'UNKNOWN_ERROR',
}
export enum PaymentMethodType {
  FuelCard = 'FUEL_CARD',
}
export enum PaymentPartnerType {
  MockFuelingPayment = 'MOCK_FUELING_PAYMENT',
  UtaPayment = 'UTA_PAYMENT',
}
export enum UnitType {
  Hours = 'HOURS',
  Kg = 'KG',
  Kwh = 'KWH',
  Liter = 'LITER',
  Pieces = 'PIECES',
}
export enum ErrorCode {
  InvalidPathParameters = 'INVALID_PATH_PARAMETERS',
  InvalidQueryParameters = 'INVALID_QUERY_PARAMETERS',
  InvalidPayload = 'INVALID_PAYLOAD',
  InvalidFuelCard = 'INVALID_FUEL_CARD',
  InvalidStatusChange = 'INVALID_STATUS_CHANGE',
  CancelationNotAllowedTransactionAlreadyCanceled = 'CANCELATION_NOT_ALLOWED_TRANSACTION_ALREADY_CANCELED',
  CancelationNotAllowedTransactionNotStarted = 'CANCELATION_NOT_ALLOWED_TRANSACTION_NOT_STARTED',
  CancelationNotAllowedTransactionTransactionFinished = 'CANCELATION_NOT_ALLOWED_TRANSACTION_TRANSACTION_FINISHED',
  PoiNotFound = 'POI_NOT_FOUND',
  OnlinePaymentNotSupported = 'ONLINE_PAYMENT_NOT_SUPPORTED',
  PaymentMethodActive = 'PAYMENT_METHOD_ACTIVE',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
  VehicleServiceNotActivated = 'VEHICLE_SERVICE_NOT_ACTIVATED',
  VehicleDoesNotHaveLicensePlate = 'VEHICLE_DOES_NOT_HAVE_LICENSE_PLATE',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  PaymentMethodNotUsableAtLocation = 'PAYMENT_METHOD_NOT_USABLE_AT_LOCATION',
  BookingNotCancelable = 'BOOKING_NOT_CANCELABLE',
  UnknownError = 'UNKNOWN_ERROR',
}
export enum Status {
  Canceled = 'CANCELED',
}
export const {
  useGetTransactionsQuery,
  useGetTransactionByIdQuery,
  usePostFuelingTransactionsMutation,
  usePatchFuelingTransactionMutation,
} = injectedRtkApi;
