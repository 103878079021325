import React, { ReactNode } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

type LoadingPanelProps = {
  loading: boolean;
  children: ReactNode;
};

export const LoadingPanel: React.FC<LoadingPanelProps> = ({
  loading,
  children,
}) => {
  return loading ? (
    <div
      className={
        'height-150 display-flex justify-content-center align-items-center text-size-16'
      }
    >
      <Spinner isDoubleSized />
    </div>
  ) : (
    <>{children}</>
  );
};
