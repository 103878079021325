const configureRouteStorage = (window: Window) => {
  const { localStorage } = window;
  const routeKey = 'app_route';
  const vehicleIdKey = 'app_last_vehicle_id';
  const loginError = 'app_external_login_error';

  return {
    getRoute: () => localStorage.getItem(routeKey),
    saveRoute: (route: string) => localStorage.setItem(routeKey, route),
    getLastVehicleId: () => localStorage.getItem(vehicleIdKey),
    saveVehicleId: (vehicleId: string) =>
      localStorage.setItem(vehicleIdKey, vehicleId),
    clearLastVehicleId: () => localStorage.removeItem(vehicleIdKey),
    getExternalLoginError: () => localStorage.getItem(loginError),
    saveExternalLoginError: (externalLoginError: string) =>
      localStorage.setItem(loginError, externalLoginError),
    clearExternalLoginError: () => localStorage.removeItem(loginError),
  };
};

export const routeStorage = configureRouteStorage(window);
