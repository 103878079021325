import { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { stringify as csvStringify } from 'csv-stringify/sync';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { mapTransactionsToRows, TransactionRow } from './mapTransactionsToRows';
import { RowData } from '../../../../components/table/Table';
import {
  PAYMENT_METHOD_TO_TRANSLATION_KEY,
  SERVICE_PARTNER_TO_TRANSLATION_KEY,
  SERVICE_TO_TRANSLATION_KEY,
  TRANSACTION_STATE_TO_TRANSLATION_KEY,
} from '../../../../i18n/enumMappings';
import {
  CustomFormattedMessage,
  dateFormat,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import { getCurrencyDisplay } from '../../../../components/MonetaryValue';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { TransactionWithVehicle } from '../../../../models';
import { useAppSelector } from '../../../../state/hooks';
import {
  getFromFilter,
  getToFilter,
  getTransactionCurrenciesFilter,
  getVehicleGroupsFilter,
  getVehiclesFilter,
} from '../../../../state/transactions/transactionFilterSlice';
import { useTransactions } from '../../../../state/transactions/useTransactions';

const DOWNLOAD_FILENAME_SUFFIX = 'MAN_SimplePay_Transactions';

export const CsvExportButton = () => {
  const [isExportingTransactions, setIsExportingTransactions] =
    useState<boolean>(false);

  const { formatDate, formatNumber } = useIntl();
  const intl = useCustomIntl();

  const { vehicles: vehiclesWithGroups } = useVehicles();
  const vehicleGroups = useAppSelector(getVehicleGroupsFilter);
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const transactionCurrencies = useAppSelector(getTransactionCurrenciesFilter);
  const vehicles = useAppSelector(getVehiclesFilter);

  const transactionsVehicleMap: Map<string, TransactionWithVehicle> = new Map();

  const { transactions } = useTransactions({
    vehicleId: vehicles.length !== 0 ? vehicles : undefined,
    vehicleGroupId: vehicleGroups.length !== 0 ? vehicleGroups : undefined,
    currency:
      transactionCurrencies.length !== 0 ? transactionCurrencies : undefined,
    fromCreatedAt: moment(from).format(),
    toCreatedAt: moment(to).format(),
  });

  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  const convertToCsv = (transactionRows: RowData<TransactionRow>[]): string => {
    const headers: string[] = [
      getLabel('transactions.transactionsTable.header.vehicle'),
      getLabel('transactions.transactionsTable.header.service'),
      getLabel('common.servicePartner'),
      getLabel('transactions.transactionsTable.header.createdAt'),
      getLabel('transactions.transactionsTable.header.paymentMethod'),
      getLabel('common.transaction.totalPriceGross'),
      getLabel('transactions.transactionsTable.header.status'),
    ];
    const tableData: string[][] = transactionRows.map(
      (transactionRow: RowData<TransactionRow>) => [
        transactionRow.vehicleName ?? '',
        getLabel(SERVICE_TO_TRANSLATION_KEY[transactionRow.service]),
        getLabel(
          SERVICE_PARTNER_TO_TRANSLATION_KEY[transactionRow.servicePartner],
        ),
        formatDate(transactionRow.createdAt, { ...dateFormat }),
        getLabel(
          PAYMENT_METHOD_TO_TRANSLATION_KEY[transactionRow.paymentMethod],
        ),
        !!transactionRow.totalPriceGross && !!transactionRow.currency
          ? formatNumber(transactionRow.totalPriceGross, {
              currency: transactionRow.currency,
              style: 'currency',
              currencyDisplay: getCurrencyDisplay(transactionRow.currency),
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : '',
        getLabel(TRANSACTION_STATE_TO_TRANSLATION_KEY[transactionRow.status]),
      ],
    );

    return csvStringify([headers, ...tableData], {
      delimiter: ';',
      quoted: true,
      quoted_empty: true,
    });
  };

  const utf8Encode = (inputString: string): Uint8Array => {
    const textEncoder = new TextEncoder();
    return textEncoder.encode(inputString);
  };

  const triggerDownloadCsvFile = (
    fileContent: Uint8Array,
    filename: string,
  ) => {
    const blob = new Blob([fileContent], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    const href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('href', href);
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleExportTransactions = async () => {
    setIsExportingTransactions(true);
    try {
      const transactionRows: RowData<TransactionRow>[] = mapTransactionsToRows(
        transactions,
        vehiclesWithGroups,
        transactionsVehicleMap,
      );
      const csvData: string = convertToCsv(transactionRows);
      const utf8CsvData: Uint8Array = utf8Encode(csvData);
      const formatedDatePrefix = moment().format('yyyyMMDD');
      const downloadFilename = `${formatedDatePrefix}_${DOWNLOAD_FILENAME_SUFFIX}.csv`;
      triggerDownloadCsvFile(utf8CsvData, downloadFilename);
    } catch (err) {
      console.error('Error exporting transactions table:', err);
    }
    setIsExportingTransactions(false);
  };

  const loadingClass = isExportingTransactions ? 'btn-loading' : '';

  return (
    <Button
      bsStyle={'primary'}
      className={loadingClass}
      disabled={isExportingTransactions}
      onClick={handleExportTransactions}
    >
      <span className={'rioglyph rioglyph-download'} />
      <CustomFormattedMessage
        id={'transactions.transactionsTable.toolbar.export'}
      />
    </Button>
  );
};
