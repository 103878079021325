import { ServiceIcon } from '../icons/ServiceIcon';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { SERVICE_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { ServiceType } from '../../codegen/vehicles';

export type ServiceRepresentationSize = 'medium' | 'large';

const iconClassesBySize: { [k in ServiceRepresentationSize]: string } = {
  medium: 'text-size-18 margin-right-5',
  large: 'text-size-20 margin-right-10',
};

export const ServiceRepresentation = ({
  serviceType,
  representationSize = 'medium',
}: {
  serviceType: ServiceType;
  representationSize?: ServiceRepresentationSize;
}) => {
  return (
    <>
      <ServiceIcon
        service={serviceType}
        className={`${iconClassesBySize[representationSize]} text-color-dark`}
      />
      <CustomFormattedMessage id={SERVICE_TO_TRANSLATION_KEY[serviceType]} />
    </>
  );
};
