import React from 'react';
import {
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { MultiselectOption } from '@rio-cloud/rio-uikit/lib/es/types';

type VehicleTableToolbarProps = {
  groupFilterOptions: MultiselectOption[];
  onGroupFilterChanged: (selectedOptions: string[]) => void;
  searchBarValue: string;
  onSearchBarValueChanged: (value: string) => void;
};

export const VehicleTableToolbar: React.FC<VehicleTableToolbarProps> = ({
  groupFilterOptions,
  onGroupFilterChanged,
  searchBarValue,
  onSearchBarValueChanged,
}) => {
  const intl = useCustomIntl();
  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  return (
    <TableToolbar>
      <div
        className="table-toolbar-container"
        data-testid={'onboarding-table-toolbar'}
      >
        <div className="table-toolbar-group-left">
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid="onboarding-vehicle-table-group-filter">
                <label>
                  <CustomFormattedMessage id={'common.vehicle.groups'} />
                </label>
                <Multiselect
                  placeholder={getLabel(
                    'onboarding.vehicleTable.toolbar.vehicleGroupFilterPlaceholder',
                  )}
                  options={groupFilterOptions}
                  useFilter
                  onChange={onGroupFilterChanged}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-toolbar-group-right">
          <div className="table-toolbar-column">
            <TableSearch
              value={searchBarValue}
              onChange={onSearchBarValueChanged}
              placeholder={getLabel(
                'onboarding.vehicleTable.toolbar.searchBarPlaceholder',
              )}
            />
          </div>
        </div>
      </div>
    </TableToolbar>
  );
};
