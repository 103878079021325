import React from 'react';
import classNames from 'classnames';
import { VehicleType } from '../../codegen/vehicles';

type VehicleIconProps = {
  type: VehicleType;
  className: string;
};

const vehicleTypesToIcon: { [key in VehicleType]: string } = {
  TRUCK: 'rioglyph rioglyph-truck',
  VAN: 'rioglyph rioglyph-van',
  BUS: 'rioglyph rioglyph-bus',
  TRAILER: 'rioglyph rioglyph-trailer',
  CAR: 'rioglyph rioglyph-car',
};

export const VehicleIcon: React.FC<VehicleIconProps> = ({
  type,
  className,
}) => <span className={classNames(vehicleTypesToIcon[type], className)} />;
