import React from 'react';

type MaskedCardNumberProps = {
  lastFourDigits: string | null | undefined;
};

export const MaskedCardNumber: React.FC<MaskedCardNumberProps> = ({
  lastFourDigits,
}) => {
  return lastFourDigits ? (
    <>
      {`**** **** **** ***${lastFourDigits[0]} ${lastFourDigits.slice(1, 4)}`}
    </>
  ) : (
    <></>
  );
};
