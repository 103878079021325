import React, { useMemo } from 'react';
import { DashboardPanel } from './DashboardPanel';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';
import { VehicleIcon } from '../../../components/icons/VehicleIcon';
import { FormattedPlural } from 'react-intl';
import {
  VEHICLE_TYPE_PLURAL_TO_TRANSLATION_KEY,
  VEHICLE_TYPE_TO_TRANSLATION_KEY,
} from '../../../i18n/enumMappings';
import { LoadingPanel } from '../../../components/LoadingPanel';
import { InfoPopup } from '../../../components/InfoPopup';
import { useDashboardFilteredVehicles } from '../../../state/transactions/transactionFilterSlice';
import { VehicleWithGroups } from '../../../models';
import { VehicleType } from '../../../codegen/vehicles';

export const VehicleInformationPanel: React.FC = () => {
  const intl = useCustomIntl();

  const { vehicles, isLoading: isLoadingVehicles } =
    useDashboardFilteredVehicles();

  const trucksCount = useMemo<number>(() => {
    const trucks: VehicleWithGroups[] = vehicles.filter(
      (vehicle: VehicleWithGroups) => vehicle.type === VehicleType.Truck,
    );
    return trucks.length;
  }, [vehicles]);

  return (
    <DashboardPanel
      header={
        <div className={'display-flex align-items-center'}>
          {intl.formatMessage({
            id: 'dashboard.panels.vehicleInformation.header',
          })}
          <div
            data-testid="dashboard-vehicle-information-information-icon-wrapper"
            className={'margin-left-5'}
          >
            <InfoPopup
              dataTestid="dashboard-vehicle-information-information-tooltip"
              marginTop={0}
              placement={'right'}
            >
              <CustomFormattedMessage
                id={'dashboard.panels.vehicleInformation.informationTooltip'}
              />
            </InfoPopup>
          </div>
        </div>
      }
      testId="dashboard-vehicle-information-panel"
      body={
        <LoadingPanel loading={isLoadingVehicles}>
          <div
            className={
              'height-150 display-flex justify-content-center align-items-center text-size-16'
            }
          >
            <VehicleIcon
              type={VehicleType.Truck}
              className={'text-size-200pct'}
            />
            <span
              className={'padding-left-10'}
              data-testid={'dashboard-vehicle-information-panel-content'}
            >
              {trucksCount + ' '}
              <FormattedPlural
                value={trucksCount}
                one={
                  <CustomFormattedMessage
                    id={VEHICLE_TYPE_TO_TRANSLATION_KEY[VehicleType.Truck]}
                  />
                }
                other={
                  <CustomFormattedMessage
                    id={
                      VEHICLE_TYPE_PLURAL_TO_TRANSLATION_KEY[VehicleType.Truck]
                    }
                  />
                }
              />
            </span>
          </div>
        </LoadingPanel>
      }
    />
  );
};
