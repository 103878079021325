import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import React, { ReactNode } from 'react';

type InfoPopupProps = {
  dataTestid?: string;
  zIndex?: number;
  width?: number;
  marginTop?: number;
  placement?: string;
  children: ReactNode;
};

export const InfoPopup: React.FC<InfoPopupProps> = ({
  dataTestid,
  children,
  zIndex,
  width = 250,
  marginTop = 20,
  placement = 'bottom',
}) => {
  let paddingInfo = '';
  switch (placement) {
    case 'bottom':
      paddingInfo = 'padding-top-10';
      break;
    case 'right':
      paddingInfo = 'padding-right-10';
      break;
  }

  return (
    <OverlayTrigger
      trigger="click"
      key={'bottom'}
      placement={placement}
      overlay={
        <Popover
          placement={placement}
          id={'info-popup-popover'}
          style={zIndex && { zIndex }}
        >
          <div
            data-testid={dataTestid}
            className={`width-${width}`}
          >
            {children}
          </div>
        </Popover>
      }
    >
      <div className={`margin-top-${marginTop}`}>
        <span
          className={
            'rioglyph rioglyph-question-sign text-color-info text-size-20 ' +
            `margin-top-3 display-inline-block cursor-pointer ${paddingInfo}`
          }
        />
      </div>
    </OverlayTrigger>
  );
};
