import { Tag } from '../Tag';

type VehicleGroupsRepresentationProps = {
  vehicleGroups: string[];
};

export const VehicleGroupsRepresentation = ({
  vehicleGroups,
}: VehicleGroupsRepresentationProps) => {
  return (
    <>
      {vehicleGroups.map((group) => (
        <Tag key={group}>{group}</Tag>
      ))}
    </>
  );
};
