import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../hooks';

export type TransactionUpdateState = {
  hasTransactionUpdate: boolean;
  needToNotify: boolean;
};

const initialState: TransactionUpdateState = {
  hasTransactionUpdate: false,
  needToNotify: true,
};

export const transactionUpdateSlice = createSlice({
  name: 'transactionUpdate',
  initialState,
  reducers: {
    setHasTransactionUpdate: (state, action: PayloadAction<boolean>) => {
      state.hasTransactionUpdate = action.payload;
    },
    setNeedToNotify: (state, action: PayloadAction<boolean>) => {
      state.needToNotify = action.payload;
    },
  },
});

export const useTransactionUpdateState = () => {
  const dispatch = useAppDispatch();

  const hasTransactionUpdate = useAppSelector(
    (state) => state.transactionUpdate.hasTransactionUpdate,
  );
  const needToNotify = useAppSelector(
    (state) => state.transactionUpdate.needToNotify,
  );

  const setHasTransactionUpdate = (newHasTransactionUpdate: boolean) => {
    dispatch(
      transactionUpdateSlice.actions.setHasTransactionUpdate(
        newHasTransactionUpdate,
      ),
    );
  };
  const setNeedToNotify = (newNeedToNotify: boolean) => {
    dispatch(transactionUpdateSlice.actions.setNeedToNotify(newNeedToNotify));
  };

  const reset = () => {
    setHasTransactionUpdate(initialState.hasTransactionUpdate);
    setNeedToNotify(initialState.needToNotify);
  };

  return {
    hasTransactionUpdate,
    needToNotify,
    setHasTransactionUpdate,
    setNeedToNotify,
    reset,
  };
};
