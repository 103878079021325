import React from 'react';
import classNames from 'classnames';
import { ServiceType } from '../../codegen/vehicles';

type ServiceIconProps = {
  service: ServiceType;
  className?: string;
};

const serviceTypesToIcon: { [key in ServiceType]: string } = {
  FUELING: 'rioglyph rioglyph-filling-station',
  BREAKDOWN: 'rioglyph rioglyph-workshop',
  PARKING: 'rioglyph rioglyph-parking',
  WASHING: 'rioglyph rioglyph-stars',
};

export const ServiceIcon: React.FC<ServiceIconProps> = ({
  service,
  className,
}) => <span className={classNames(serviceTypesToIcon[service], className)} />;
