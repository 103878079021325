import { GraphqlRequestType, Request } from './baseQuery';
import { parse } from 'graphql';

export const listVehicleGroups: Request = {
  type: GraphqlRequestType.QUERY,
  definition: parse(`query listVehicleGroups {
    listVehicleGroups {
      items {
        id
        name
      }
    }
  }`),
};
