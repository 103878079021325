import {
  enhancedApi,
  useGetVehiclesQuery,
  Vehicle,
} from '../../codegen/vehicles';
import { getUser } from '../../login/loginSlice';
import { mergeVehicleWithGroups, VehicleWithGroups } from '../../models';
import { useAppSelector } from '../hooks';
import { useGetVehicleGroupsQuery } from '../vehicleGroups/vehicleGroupsSlice';
import moment from 'moment';
import { useMemo } from 'react';

export const useVehicles = () => {
  const user = useAppSelector(getUser);
  const vehiclesQuery = useGetVehiclesQuery();
  const groupsQuery = useGetVehicleGroupsQuery({ user });

  const vehicleItems = vehiclesQuery?.data?.items || [];
  const groups = groupsQuery.data || [];

  // TODO: In JUP-3052: check if this can be moved into a transformResponse function
  const vehicles: VehicleWithGroups[] = useMemo(
    () =>
      vehicleItems.map((vehicle: Vehicle) => {
        return mergeVehicleWithGroups(vehicle, groups);
      }),
    [vehicleItems, groups],
  );

  return {
    vehicles,
    groups,
    isLoading:
      vehiclesQuery.isLoading ||
      groupsQuery.isLoading ||
      vehiclesQuery.isFetching ||
      groupsQuery.isFetching,
    isFetching: vehiclesQuery.isFetching || groupsQuery.isFetching,
    error: vehiclesQuery.error || groupsQuery.error,
  };
};

const transformPaymentMethods = (vehicle: Vehicle) => {
  vehicle.paymentMethods = vehicle.paymentMethods.map((paymentMethod) => {
    const convertedExpiry = moment(paymentMethod.expiry, 'MM-YY').format(
      'MM/YYYY',
    );
    return {
      ...paymentMethod,
      expiry: convertedExpiry,
    };
  });
  return vehicle;
};

enhancedApi.enhanceEndpoints({
  endpoints: {
    getVehicles: {
      transformResponse: (response) => {
        response.items.map((vehicle: Vehicle) => {
          return transformPaymentMethods(vehicle);
        });
        return response;
      },
    },
    updateServiceActivations: {
      transformResponse: (response) => {
        return transformPaymentMethods(response);
      },
    },
  },
});
