import React from 'react';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';

export const UserMenu: React.FC = () => {
  return (
    <DefaultUserMenu
      environment={
        process.env.NODE_ENV === 'production' ? 'production' : 'local'
      }
    />
  );
};
