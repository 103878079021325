import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { loginRio } from './login/loginRio';
import { Provider } from 'react-redux';
import { createStore } from './state/store';
import { ErrorBoundary } from './ErrorBoundary';
import { App } from './App';
import { handleExternalLogin } from './login/external/externalLogin';

const container = document.getElementById('root')!;
const root = createRoot(container);

handleExternalLogin()
  .then(loginRio)
  .then((user) => {
    if (!user) {
      return <></>;
    }
    return root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <Provider store={createStore(user)}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>,
    );
  });
