import {
  ServiceType,
  useGetParkingLocationQuery,
  useGetWashingLocationQuery,
} from '../../codegen/locations';

export const useLocations = (locationId: string, serviceType: string) => {
  if (serviceType === ServiceType.Parking) {
    const parkingLocationsQuery = useGetParkingLocationQuery({
      id: locationId,
    });

    return {
      location: parkingLocationsQuery.data,
      isLoading: parkingLocationsQuery.isLoading,
      error: parkingLocationsQuery.error,
    };
  } else if (serviceType === ServiceType.Washing) {
    const washingLocationsQuery = useGetWashingLocationQuery({
      id: locationId,
    });

    return {
      location: washingLocationsQuery.data,
      isLoading: washingLocationsQuery.isLoading,
      error: washingLocationsQuery.error,
    };
  } else {
    return {
      location: undefined,
    };
  }
};
