import { CustomFormattedMessage, TranslationsKeys } from '../../i18n/i18n';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import React, { ReactNode } from 'react';

type SidebarRowProps = {
  label: TranslationsKeys;
  labelValues?: Record<
    string,
    PrimitiveType | FormatXMLElementFn<string, string>
  >;
  show?: boolean;
  highlight?: boolean;
  children?: ReactNode;
};

export const SidebarRow: React.FC<SidebarRowProps> = ({
  label,
  labelValues,
  show = true,
  highlight = false,
  children,
}) => {
  const textStyle = highlight
    ? 'text-color-black text-medium'
    : 'text-color-gray';

  if (!show) {
    return <></>;
  }

  return (
    <tr>
      <td className={textStyle}>
        <CustomFormattedMessage
          id={label}
          values={labelValues}
        />
      </td>
      <td className={'text-right'}>{children}</td>
    </tr>
  );
};
