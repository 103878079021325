import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';

type OperationalCostPanelFooterProps = {
  isLoading: boolean;
  totalAmount: number;
  currency: string;
  onButtonClick?: () => void;
};

export const OperationalCostPanelFooter: React.FC<
  OperationalCostPanelFooterProps
> = ({ isLoading, totalAmount, currency, onButtonClick }) => {
  if (isLoading || totalAmount <= 0) {
    return <></>;
  }

  return (
    <>
      <span
        className="rioglyph rioglyph-stats text-color-gray
               padding-5 text-size-20"
      />
      <span data-testid={'dashboard-operational-costs-panel-footer'}>
        <CustomFormattedMessage
          id={'dashboard.panels.operationalCosts.footer'}
        />
        {': '}
        <b>
          <MonetaryValue
            amount={totalAmount}
            currency={currency}
            countUp
          />
        </b>
      </span>
      <span
        data-testid={'dashboard-operational-costs-filter-transaction-button'}
        className={
          'rioglyph rioglyph-th-list cursor-pointer text-color-primary margin-left-10'
        }
        onClick={onButtonClick}
      />
    </>
  );
};
