import { VehicleType } from '../../codegen/vehicles';
import { VehicleIcon } from '../icons/VehicleIcon';

type VehicleRepresentationProps = {
  vehicleType?: VehicleType;
  vehicleName?: string;
};

export const VehicleRepresentation = ({
  vehicleType,
  vehicleName,
}: VehicleRepresentationProps) => {
  return (
    <>
      {vehicleType && (
        <VehicleIcon
          type={vehicleType}
          className="text-size-18 text-color-dark"
        />
      )}
      <span className="margin-left-10">{vehicleName}</span>
    </>
  );
};
