import React from 'react';
import classNames from 'classnames';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { NoDataPanelBody } from '../../../../components/NoDataPanelBody';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { LoadingPanel } from '../../../../components/LoadingPanel';

type TotalCostPanelBodyProps = {
  loading: boolean;
  totalAmount: number;
  averagePerVehicle: number;
  currency: string;
};

export const TotalCostPanelBody: React.FC<TotalCostPanelBodyProps> = ({
  loading,
  totalAmount,
  averagePerVehicle,
  currency,
}) => {
  const leftColumn = 'col-7 text-center text-size-16 padding-0';
  const rightColumn = 'col-5 text-right text-size-20 margin-top-10';

  return (
    <LoadingPanel loading={loading}>
      <div data-testid="dashboard-total-cost-panel-content">
        {!totalAmount ? (
          <NoDataPanelBody />
        ) : (
          <div className={'padding-left-20 padding-right-20'}>
            <div className={'row'}>
              <div className={`${leftColumn} margin-bottom-5`}>
                <span
                  className={
                    'rioglyph rioglyph-cost-efficency text-size-200pct'
                  }
                />
                <div>
                  <CustomFormattedMessage
                    id={'dashboard.panels.totalCost.totalExpenses'}
                  />
                </div>
              </div>
              <div className={rightColumn}>
                <MonetaryValue
                  amount={totalAmount}
                  currency={currency}
                  countUp
                />
              </div>
            </div>
            <div className={'row padding-top-20'}>
              <div className={`${leftColumn} margin-bottom-5`}>
                <span className="indicated-icon">
                  <span
                    className={'rioglyph rioglyph-truck text-size-200pct'}
                  />
                  <span
                    className={classNames([
                      'badge',
                      'badge-indicator',
                      'badge-indicator-border',
                      'badge-default',
                      'rioglyph',
                      'rioglyph-dashboard',
                      'text-size-20',
                    ])}
                  />
                </span>
                <div>
                  <CustomFormattedMessage
                    id={'dashboard.panels.totalCost.averagePerVehicle'}
                  />
                </div>
              </div>
              <div className={rightColumn}>
                <MonetaryValue
                  amount={averagePerVehicle}
                  currency={currency}
                  countUp
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadingPanel>
  );
};
