import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import React, { useEffect } from 'react';
import { AppRoutes } from './routes/AppRoutes';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, getMessagesForLocale } from './i18n/i18n';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { VehicleSidebar } from './pages/onboarding/components/sidebar/VehicleSidebar';
import { useLocale } from './i18n/useLocale';
import { Header } from './components/header/Header';
import { useVehicles } from './state/vehicles/useVehicles';
import { getUser } from './login/loginSlice';
import { useAppSelector } from './state/hooks';
import { TransactionSidebar } from './pages/transactions/components/sidebar/TransactionSidebar';
import { VehicleAssetTree } from './pages/transactions/components/assetTree/VehicleAssetTree';
import { SupportDialog } from './components/dialog/supportDialog/SupportDialog';
import { EVENT_USER_LANGUAGE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { UserMenuLanguageChangedEvent } from './i18n/localeSlice';
import {
  graphqlBaseSubscription,
  GraphQlOperation,
  Subscription,
} from './graphql/baseQuery';
import { onTransactionUpdated } from './graphql/subscriptions';
import { TransactionSubscriptionEvent } from './state/transactions/dto';
import { useTransactionUpdateState } from './state/transactions/transactionUpdateSlice';
import { BookingSidebar } from './pages/bookings/components/sidebar/BookingSidebar';

export const App: React.FC = () => {
  const { locale, setLocale } = useLocale();
  const messages = getMessagesForLocale(locale);
  const user = useAppSelector(getUser);

  useVehicles();

  const { setHasTransactionUpdate } = useTransactionUpdateState();

  const handleLanguageChanged = (event: Event) => {
    const language = (event as UserMenuLanguageChangedEvent).detail?.language;
    setLocale(language);
  };

  const handleTransactionUpdate = (event: TransactionSubscriptionEvent) => {
    setHasTransactionUpdate(true);
  };

  useEffect(() => {
    const request: GraphQlOperation<Subscription> = {
      user,
      request: onTransactionUpdated,
      requestParams: { account_id: user.accountId },
    };
    graphqlBaseSubscription(request, handleTransactionUpdate);

    setLocale(user.locale);
    document.addEventListener(
      EVENT_USER_LANGUAGE_CHANGED,
      handleLanguageChanged,
    );
  }, []);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale}
      messages={messages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <Header />
        </ApplicationLayout.Header>
        <VehicleSidebar />
        <VehicleAssetTree />
        <TransactionSidebar />
        <BookingSidebar />
        <ApplicationLayout.Body>
          <SupportDialog />
          <NotificationsContainer />
          <AppRoutes />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  );
};
