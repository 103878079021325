import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../state/store';
import { DEFAULT_LOCALE } from './i18n';

export type LocaleState = {
  locale: string;
};

const initialState: LocaleState = {
  locale: DEFAULT_LOCALE,
};

export type UserMenuLanguageChangedEvent = {
  detail: {
    language: string;
  };
} & Event;

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = localeSlice.actions;
export const getLocale = (state: RootState) => state.locale.locale;
