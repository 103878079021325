import { useMemo } from 'react';
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Formatter as TooltipFormatter,
  Payload as TooltipPayload,
} from 'recharts/types/component/DefaultTooltipContent';
import { ServiceType } from '../../../../codegen/transactions';
import { serviceTypeColors } from '../../../../components/colors/ServiceTypeColors';
import { commonColors } from '../../../../components/colors/commonColors';
import { useCustomIntl } from '../../../../i18n/i18n';
import { SERVICE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { useAppSelector } from '../../../../state/hooks';
import { getDashboardCurrencyFilter } from '../../../../state/transactions/transactionFilterSlice';
import { CostsPerService } from './useChartData';
import { getCurrencyDisplay } from '../../../../components/MonetaryValue';

const serviceTypes: ServiceType[] = Object.values(ServiceType);
const serviceTypeDataKeys: string[] = serviceTypes.map(
  (serviceType: string) => `costsPerService.${serviceType}`,
);

type ChartEntry = {
  label: string;
  costsPerService: CostsPerService;
  numberOfVehicles: number;
};
export type ChartData = ChartEntry[];

type ServiceTypesHaveTransactions = Record<ServiceType, boolean>;

const serviceTypeHasTransactions = (
  serviceType: ServiceType,
  draft: ServiceTypesHaveTransactions,
  chartEntry: ChartEntry,
) => {
  return draft[serviceType] || chartEntry.costsPerService[serviceType] !== 0;
};

const calculateWhetherServiceTypesHaveTransactions = (
  chartData: ChartData,
): ServiceTypesHaveTransactions => {
  const draft: ServiceTypesHaveTransactions = {
    [ServiceType.Fueling]: false,
    [ServiceType.Breakdown]: false,
    [ServiceType.Parking]: false,
    [ServiceType.Washing]: false,
  };
  chartData.forEach((chartEntry) => {
    serviceTypes.forEach((serviceType: ServiceType) => {
      draft[serviceType] = serviceTypeHasTransactions(
        serviceType,
        draft,
        chartEntry,
      );
    });
  });
  return draft;
};

/* The following values are based on practical experimentation */
const INITIAL_Y_AXIS_WIDTH = 17;
const EXTRA_Y_AXIS_WIDTH_PER_DIGIT = 7;

const calculateYAxisWidth = (maxValue: number): number => {
  const aproxNumberOfIntegerDigits = Math.ceil(Math.log10(maxValue + 1));
  return (
    INITIAL_Y_AXIS_WIDTH +
    (aproxNumberOfIntegerDigits - 1) * EXTRA_Y_AXIS_WIDTH_PER_DIGIT
  );
};

const calculateLeftYAxisWidth = (chartData: ChartData): number => {
  const maxValue: number = Math.max(
    ...chartData.map((chartEntry) => {
      const costsForMonth: number[] = Object.values(chartEntry.costsPerService);
      const totalCostForMonth: number = costsForMonth.reduce(
        (accumulator: number, currentValue: number) => {
          return accumulator + currentValue;
        },
        0,
      );
      return totalCostForMonth;
    }),
  );
  return calculateYAxisWidth(maxValue);
};

const calculateRightYAxisWidth = (chartData: ChartData): number => {
  const maxValue: number = Math.max(
    ...chartData.map((chartEntry) => chartEntry.numberOfVehicles),
  );
  return calculateYAxisWidth(maxValue);
};

type Props = {
  chartData: ChartData;
};

export const CostsPerServiceOverTimeChart = ({ chartData }: Props) => {
  const intl = useCustomIntl();

  const currency = useAppSelector(getDashboardCurrencyFilter);

  const serviceTypesHaveTransactions = useMemo<ServiceTypesHaveTransactions>(
    () => calculateWhetherServiceTypesHaveTransactions(chartData),
    [chartData],
  );

  const tooltipFormatter: TooltipFormatter<number | string, string> = (
    value: number | string,
    _name: string,
    item: TooltipPayload<number | string, string>,
    _index: number,
    _payload: TooltipPayload<number | string, string>[],
  ): string | number => {
    if (serviceTypeDataKeys.includes(item.dataKey as string)) {
      return intl.formatNumber(value as number, {
        style: 'currency',
        currency,
        currencyDisplay: getCurrencyDisplay(currency),
      });
    }
    return value;
  };

  return (
    <div
      data-testid="costs-per-service-over-time-panel-chart"
      className="panel-default panel-body margin-top-0 margin-bottom-0 height-200 max-width-500"
    >
      <ComposedChart
        data={chartData}
        layout="horizontal"
        width={318}
        height={170}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <XAxis dataKey="label" />
        <YAxis
          yAxisId="left-axis"
          orientation="left"
          type="number"
          width={calculateLeftYAxisWidth(chartData)}
        />
        <YAxis
          yAxisId="right-axis"
          dataKey="numberOfVehicles"
          orientation="right"
          type="number"
          allowDecimals={false}
          width={calculateRightYAxisWidth(chartData)}
        />

        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
        />

        <Legend
          layout="horizontal"
          verticalAlign="bottom"
          align="center"
        />

        <Tooltip formatter={tooltipFormatter} />

        {serviceTypes.map((serviceType: ServiceType) => {
          if (!serviceTypesHaveTransactions[serviceType]) {
            return <></>;
          }
          return (
            <Bar
              key={serviceType}
              yAxisId="left-axis"
              dataKey={`costsPerService.${serviceType}`}
              name={intl.formatMessage({
                id: SERVICE_TO_TRANSLATION_KEY[serviceType],
              })}
              stackId="byMonth"
              fill={serviceTypeColors[serviceType].hex}
              barSize={26}
            />
          );
        })}

        <Line
          yAxisId="right-axis"
          type="linear"
          dataKey="numberOfVehicles"
          name={intl.formatMessage({
            id: 'dashboard.panels.costsPerServiceOverTime.chart.tooltip.numberOfVehicles',
          })}
          stroke={commonColors.grayDark}
          fill={commonColors.grayDark}
          dot={false}
          isAnimationActive={false}
        />
      </ComposedChart>
    </div>
  );
};
