import { useCustomIntl } from './i18n/i18n';
import moment from 'moment';

export enum Period {
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  TOMORROW = 'TOMORROW',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
}

const periodsForTransactions = [
  Period.TODAY,
  Period.YESTERDAY,
  Period.LAST_7_DAYS,
  Period.LAST_30_DAYS,
  Period.LAST_60_DAYS,
  Period.LAST_WEEK,
  Period.LAST_MONTH,
  Period.LAST_6_MONTHS,
];

const periodsForBookings = [
  Period.NEXT_7_DAYS,
  Period.TOMORROW,
  Period.TODAY,
  Period.YESTERDAY,
  Period.LAST_7_DAYS,
  Period.LAST_30_DAYS,
  Period.LAST_60_DAYS,
  Period.LAST_WEEK,
  Period.LAST_MONTH,
];

export const parsePeriod = (value: string) => {
  return Period[value as keyof typeof Period];
};

export const usePeriodSelectOptions = () => {
  const intl = useCustomIntl();

  const periodLabels = {
    [Period.NEXT_7_DAYS]: intl.formatMessage(
      { id: 'common.timerange.nextXDays' },
      { quantity: 7 },
    ),
    [Period.TOMORROW]: intl.formatMessage({
      id: 'common.timerange.tomorrow',
    }),
    [Period.TODAY]: intl.formatMessage({
      id: 'common.timerange.today',
    }),
    [Period.YESTERDAY]: intl.formatMessage({
      id: 'common.timerange.yesterday',
    }),
    [Period.LAST_7_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 7 },
    ),
    [Period.LAST_30_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 30 },
    ),
    [Period.LAST_60_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 60 },
    ),
    [Period.LAST_WEEK]: intl.formatMessage({
      id: 'common.timerange.previousWeek',
    }),
    [Period.LAST_MONTH]: intl.formatMessage({
      id: 'common.timerange.previousMonth',
    }),
    [Period.LAST_6_MONTHS]: intl.formatMessage(
      { id: 'common.timerange.lastXMonths' },
      { quantity: 6 },
    ),
  };

  const transactionFilterPeriods = periodsForTransactions.map((t) => ({
    id: t.toString(),
    label: periodLabels[parsePeriod(t)],
  }));

  const bookingFilterPeriods = periodsForBookings.map((b) => ({
    id: b.toString(),
    label: periodLabels[parsePeriod(b)],
  }));

  return {
    transactionFilterPeriods,
    bookingFilterPeriods,
  };
};

export const getDatesForPeriod = (period: Period) => {
  switch (period) {
    case Period.NEXT_7_DAYS:
      return {
        from: moment().startOf('day'),
        to: moment().add(6, 'days').endOf('day'),
      };
    case Period.TOMORROW:
      return {
        from: moment().add(1, 'day').startOf('day'),
        to: moment().add(1, 'day').endOf('day'),
      };
    case Period.TODAY:
      return {
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.YESTERDAY:
      return {
        from: moment().subtract(1, 'days').startOf('day'),
        to: moment().subtract(1, 'days').endOf('day'),
      };
    case Period.LAST_7_DAYS:
      return {
        from: moment().subtract(6, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_30_DAYS:
      return {
        from: moment().subtract(29, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_60_DAYS:
      return {
        from: moment().subtract(59, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_WEEK:
      return {
        from: moment().subtract(1, 'week').startOf('isoWeek').startOf('day'),
        to: moment().subtract(1, 'week').endOf('isoWeek').endOf('day'),
      };
    case Period.LAST_MONTH:
      return {
        from: moment().subtract(1, 'month').startOf('month').startOf('day'),
        to: moment().subtract(1, 'month').endOf('month').endOf('day'),
      };
    case Period.LAST_6_MONTHS:
      return {
        from: moment().subtract(6, 'month').startOf('day'),
        to: moment().endOf('day'),
      };
  }
};
