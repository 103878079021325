import React from 'react';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { useAppDispatch } from '../../state/hooks';
import { openSupportDialog } from '../../pages/state/supportDialogSlice';

export const SupportMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const openSupportPopup = () => {
    dispatch(openSupportDialog());
  };

  return (
    <ActionBarItem>
      <ActionBarItem.Icon onClick={openSupportPopup}>
        <span
          className="icon rioglyph rioglyph-info-sign"
          data-testid={'base-header-support-menu-icon'}
        />
      </ActionBarItem.Icon>
    </ActionBarItem>
  );
};
