import { useMemo } from 'react';
import { DashboardPanel } from '../DashboardPanel';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { useDashboardFilteredTransactions } from '../../../../state/transactions/transactionFilterSlice';
import { InfoPopup } from '../../../../components/InfoPopup';
import { LoadingPanel } from '../../../../components/LoadingPanel';
import { ServiceType, Transaction } from '../../../../codegen/transactions';
import { NoDataPanelBody } from '../../../../components/NoDataPanelBody';
import { TransactionCountForService } from './TransactionCountForService';

type TransactionsPerService = Record<ServiceType, number>;

export const TransactionCountPerServicePanel = () => {
  const {
    filteredTransactions: transactions,
    isLoading: isLoadingTransactions,
    error: errorLoadingTransactions,
  } = useDashboardFilteredTransactions();

  const transactionCountPerService = useMemo<TransactionsPerService>(() => {
    const localTransactionsPerService: TransactionsPerService = {
      [ServiceType.Fueling]: 0,
      [ServiceType.Breakdown]: 0,
      [ServiceType.Parking]: 0,
      [ServiceType.Washing]: 0,
    };
    transactions.forEach((transaction: Transaction) => {
      localTransactionsPerService[transaction.serviceType]++;
    });
    return localTransactionsPerService;
  }, [transactions]);

  const noData = errorLoadingTransactions || transactions.length === 0;

  return (
    <DashboardPanel
      header={
        <div className="display-flex align-items-center">
          <CustomFormattedMessage id="dashboard.panels.TransactionCountPerService.header" />
          <div
            data-testid="dashboard-transaction-count-per-service-panel-information-icon-wrapper"
            className="margin-left-5"
          >
            <InfoPopup
              dataTestid="dashboard-transaction-count-per-service-panel-information-tooltip"
              marginTop={0}
              placement="right"
            >
              <CustomFormattedMessage id="dashboard.panels.TransactionCountPerService.informationTooltip" />
            </InfoPopup>
          </div>
        </div>
      }
      body={
        <LoadingPanel loading={isLoadingTransactions}>
          <div
            data-testid="dashboard-transaction-count-per-service-panel-content"
            style={{ height: 180 }}
          >
            {noData ? (
              <NoDataPanelBody />
            ) : (
              <div
                className={
                  'height-100pct display-flex flex-wrap justify-content-center align-items-center text-size-16'
                }
              >
                {Object.entries(transactionCountPerService).map(
                  ([service, count]) => (
                    <TransactionCountForService
                      key={service}
                      service={service as ServiceType}
                      count={count}
                    />
                  ),
                )}
              </div>
            )}
          </div>
        </LoadingPanel>
      }
    />
  );
};
