import {
  enhancedApi,
  PaymentMethod,
  PaymentPartnerType,
  useAddPaymentMethodMutation,
  Vehicle,
} from '../../../../codegen/vehicles';
import React from 'react';
import { useCustomIntl } from '../../../../i18n/i18n';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { useVehicleDialogFormContext } from './ServiceConfigurationForm';
import {
  addNewServiceCardDialogError,
  closeAddNewServiceCardDialog,
  getSelectedVehicle,
  isAddNewServiceCardDialogOpen,
} from '../../state/onboardingUiSlice';
import { VehicleDialogFooter } from './VehicleDialogFooter';
import { ServiceCardForm } from './ServiceCardForm';
import moment from 'moment';
import { ErrorMessagePanel } from '../../../../components/ErrorMessagePanel';
import { useEdenredToken } from '../../../../login/external/edenredUserManager';

export const AddNewServiceCardDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const showDialog = useAppSelector(isAddNewServiceCardDialogOpen);
  const vehicle = useAppSelector(getSelectedVehicle);
  const externalLoginError = useAppSelector(addNewServiceCardDialogError);
  const {
    getNewServiceCard,
    getPaymentMethods,
    setPaymentMethods,
    trigger,
    formState: { isDirty, isValid },
  } = useVehicleDialogFormContext();
  const [
    addPaymentMethod,
    { error: addPaymentMethodError, isLoading: isAddNewCardLoading, reset },
  ] = useAddPaymentMethodMutation();
  const edenredToken = useEdenredToken();

  if (!showDialog || !vehicle) {
    return <></>;
  }

  const isPaymentCardProviderLoginRequired =
    getNewServiceCard()?.paymentPartner === PaymentPartnerType.UtaPayment &&
    !edenredToken;

  const handleClose = () => {
    reset();
    dispatch(closeAddNewServiceCardDialog());
  };

  const handleAddNewCard = async () => {
    const isValidationOk = await trigger(
      ['newServiceCard.cardNumber', 'newServiceCard.expiryDate'],
      {
        shouldFocus: true,
      },
    );

    if (isValidationOk) {
      const newServiceCard = getNewServiceCard();
      const result = await addPaymentMethod({
        vehicleId: vehicle.id,
        addPaymentMethodRequest: {
          type: newServiceCard.type,
          paymentPartner: newServiceCard.paymentPartner,
          cardNumber: newServiceCard.cardNumber,
          expiry: moment(newServiceCard.expiryDate, 'MM/YYYY').format('MM-YY'),
          paymentPartnerUserToken:
            newServiceCard.paymentPartner === PaymentPartnerType.UtaPayment
              ? edenredToken
              : undefined,
        },
      });
      if ('error' in result) {
        return;
      }
      const paymentMethods: PaymentMethod[] = getPaymentMethods();
      const paymentMethod = result.data;
      paymentMethods.push({
        ...paymentMethod,
        expiry: moment(paymentMethod.expiry, 'MM-YY').format('MM/YYYY'),
      });
      setPaymentMethods(paymentMethods);
      dispatch(
        enhancedApi.util.updateQueryData(
          'getVehicles',
          undefined,
          (draftVehicles) => {
            draftVehicles.items = draftVehicles.items.map((v: Vehicle) => {
              if (v.id === vehicle.id) {
                v.paymentMethods = paymentMethods;
              }
              return v;
            });
          },
        ),
      );
      handleClose();
    }
  };

  const renderAddNewCardBody = () => (
    <>
      {(addPaymentMethodError || externalLoginError) && (
        <ErrorMessagePanel
          errorCodes={[
            addPaymentMethodError?.data?.errorCode,
            externalLoginError,
          ]}
        />
      )}
      <ServiceCardForm />
    </>
  );

  return (
    <Dialog
      aria-label={'add-service-card'}
      useOverflow
      bsSize={Dialog.SIZE_SM}
      show
      onHide={handleClose}
      title={intl.formatMessage({
        id: 'onboarding.addServiceCard.dialog.title',
      })}
      body={renderAddNewCardBody()}
      footer={
        <VehicleDialogFooter
          onClose={handleClose}
          saveEnabled={
            isValid && isDirty && !isPaymentCardProviderLoginRequired
          }
          onSave={handleAddNewCard}
          isLoading={isAddNewCardLoading}
        />
      }
    />
  );
};
