import { useMemo } from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { DashboardPanel } from '../DashboardPanel';
import { InfoPopup } from '../../../../components/InfoPopup';
import { LoadingPanel } from '../../../../components/LoadingPanel';
import { NoDataPanelBody } from '../../../../components/NoDataPanelBody';
import { useDashboardFilteredTransactions } from '../../../../state/transactions/transactionFilterSlice';
import {
  ChartData,
  CostsPerServiceOverTimeChart,
} from './CostsPerServiceOverTimeChart';
import { useChartData } from './useChartData';

export const CostsPerServiceOverTimePanel = () => {
  const {
    filteredTransactions: transactions,
    isLoading: isLoadingTransactions,
    error: errorLoadingTransactions,
  } = useDashboardFilteredTransactions();

  const { buildChartData } = useChartData();

  const chartData = useMemo<ChartData>(() => {
    return buildChartData(transactions);
  }, [transactions]);

  const noData = errorLoadingTransactions || transactions.length === 0;

  return (
    <DashboardPanel
      testId="costs-per-service-over-time-panel"
      header={
        <div className="display-flex align-items-center">
          <CustomFormattedMessage id="dashboard.panels.costsPerServiceOverTime.header" />
          <div
            data-testid="costs-per-service-over-time-panel-information-icon-wrapper"
            className="margin-left-5"
          >
            <InfoPopup
              dataTestid="costs-per-service-over-time-panel-information-tooltip"
              marginTop={0}
              placement="right"
            >
              <CustomFormattedMessage id="dashboard.panels.costsPerServiceOverTime.informationTooltip" />
            </InfoPopup>
          </div>
        </div>
      }
      body={
        <LoadingPanel loading={isLoadingTransactions}>
          <div data-testid="costs-per-service-over-time-panel-content">
            {noData ? (
              <NoDataPanelBody />
            ) : (
              <CostsPerServiceOverTimeChart chartData={chartData} />
            )}
          </div>
        </LoadingPanel>
      }
    />
  );
};
