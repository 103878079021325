import React from 'react';
import { TransactionTable } from './components/table/TransactionsTable';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import { useTableFilteredTransactions } from '../../state/transactions/transactionFilterSlice';
import { useVehicles } from '../../state/vehicles/useVehicles';
import { useHandleTransactionUpdateNotification } from '../../components/useHandleTransactionUpdateNotification';

export const TransactionPage: React.FC = () => {
  const { isLoading: isVehiclesLoading, error: errorVehicles } = useVehicles();
  const { isLoading: isTransactionsLoading, error: errorTransactions } =
    useTableFilteredTransactions();

  useHandleTransactionUpdateNotification();

  if (errorTransactions || errorVehicles) {
    return <NoDataPlaceholder />;
  }

  if (isTransactionsLoading || isVehiclesLoading) {
    return <Spinner isDoubleSized />;
  }

  return <TransactionTable />;
};
