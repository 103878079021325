import React from 'react';
import { CardDetails } from './CardDetails';
import {
  PaymentMethod,
  PaymentMethodType,
} from '../../../../../codegen/vehicles';

type PaymentMethodDetailsProps = {
  paymentMethod: PaymentMethod;
};

export const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = ({
  paymentMethod,
}) => {
  const paymentMethodToComponent: {
    [key in PaymentMethodType]: React.ReactElement;
  } = {
    FUEL_CARD: (
      <CardDetails
        paymentMethodType={paymentMethod?.type}
        paymentPartner={paymentMethod?.paymentPartner}
        lastFourDigits={paymentMethod?.lastFourDigits}
        expiry={paymentMethod?.expiry}
      />
    ),
  };

  return <>{paymentMethodToComponent[paymentMethod?.type]}</>;
};
