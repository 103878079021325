import { ServiceType } from '../../codegen/transactions';

type ColorInfo = {
  colorCssClass: string;
  textColorCssClass: string;
  /* Extracted from 'node_modules/@rio-cloud/rio-uikit/lib/es/styles/variables/colors.json' */
  hex: string;
};

export const serviceTypeColors: Record<ServiceType, ColorInfo> = {
  [ServiceType.Fueling]: {
    colorCssClass: 'color-warmup-cherokee',
    textColorCssClass: 'text-color-warmup-cherokee',
    hex: '#F5BB89',
  },
  [ServiceType.Breakdown]: {
    colorCssClass: 'color-warmup-raspberry',
    textColorCssClass: 'text-color-warmup-raspberry',
    hex: '#B23672',
  },
  [ServiceType.Parking]: {
    colorCssClass: 'color-warmup-victoria',
    textColorCssClass: 'text-color-warmup-victoria',
    hex: '#493D6D',
  },
  [ServiceType.Washing]: {
    colorCssClass: 'color-coldplay-fountain',
    textColorCssClass: 'text-color-coldplay-fountain',
    hex: '#67ABC5',
  },
};
