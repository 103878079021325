import React, { ReactNode } from 'react';

type SidebarHeadlineProps = {
  children?: ReactNode;
};

export const SidebarHeadline: React.FC<SidebarHeadlineProps> = ({
  children,
}) => {
  return (
    <tr>
      <td
        className={'text-medium margin-top-10 border-top-0'}
        colSpan={2}
      >
        {children}
      </td>
    </tr>
  );
};
