import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { InfoPopup } from '../../../../components/InfoPopup';

export const OperationalCostPanelHeader: React.FC = () => {
  return (
    <div
      data-testid={'dashboard-operational-costs-panel-header'}
      className="display-flex align-items-center"
    >
      <CustomFormattedMessage id="dashboard.panels.operationalCosts.header" />
      <div
        data-testid="dashboard-operational-cost-panel-information-icon-wrapper"
        className="margin-left-5"
      >
        <InfoPopup
          dataTestid="dashboard-operational-cost-panel-information-tooltip"
          marginTop={0}
          placement="right"
        >
          <CustomFormattedMessage id="dashboard.panels.operationalCosts.informationTooltip" />
        </InfoPopup>
      </div>
    </div>
  );
};
