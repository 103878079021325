import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import React from 'react';
import { useCustomIntl } from '../../../i18n/i18n';
import {
  closeSupportDialog,
  isSupportDialogOpen,
} from '../../../pages/state/supportDialogSlice';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { RioSupportInformation } from './RioSupportInformation';

export const SupportDialog: React.FC = () => {
  const intl = useCustomIntl();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSupportDialogOpen);

  const onHide = () => {
    dispatch(closeSupportDialog());
  };

  return (
    <Dialog
      aria-label={'support'}
      show={isOpen}
      title={intl.formatMessage({
        id: 'base.body.supportDialog.title',
      })}
      body={
        <div>
          <RioSupportInformation />
        </div>
      }
      bsSize={Dialog.SIZE_SM}
      onHide={onHide}
      showCloseButton
    />
  );
};
