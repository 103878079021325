import { FormattedNumber } from 'react-intl';
import React from 'react';
import CountUp from 'react-countup';
import { DEFAULT_LOCALE } from '../i18n/i18n';

const NARROW_SYMBOL_CURRENCIES = ['EUR', 'USD', 'GBP'];

export const getCurrencyDisplay = (currency: string) => {
  return NARROW_SYMBOL_CURRENCIES.includes(currency) ? 'narrowSymbol' : 'code';
};

export const monetaryValueOptions = (
  currency: string,
  maximumFractionDigits: number = 2,
): {
  currency: string;
  style: 'currency';
  currencyDisplay: 'narrowSymbol' | 'code';
  maximumFractionDigits: number;
} => {
  return {
    style: 'currency',
    currency,
    currencyDisplay: getCurrencyDisplay(currency),
    maximumFractionDigits,
  };
};

type MonetaryValueProps = {
  amount: number;
  currency: string;
  maximumFractionDigits?: number;
  countUp?: boolean;
};

export const MonetaryValue: React.FC<MonetaryValueProps> = ({
  amount,
  currency,
  maximumFractionDigits = 2,
  countUp = false,
}) => {
  const currencySymbol = Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currencyDisplay: getCurrencyDisplay(currency),
    currency,
  })
    .formatToParts(0)
    .filter((part) => part.type === 'currency')
    .map((part) => part.value)
    .join('');

  if (!isFinite(amount) || isNaN(amount)) {
    return `${currencySymbol} -`;
  }

  if (countUp) {
    return (
      <CountUp
        useEasing
        end={amount}
        decimals={maximumFractionDigits}
        prefix={currencySymbol + ' '}
      />
    );
  }

  const options = monetaryValueOptions(currency, maximumFractionDigits);
  return (
    <FormattedNumber
      value={amount}
      {...options}
    />
  );
};
