import React from 'react';

type HighlightPanelProps = {
  type: 'warning' | 'danger';
  children: React.ReactNode;
};

export const HighlightPanel: React.FC<HighlightPanelProps> = ({
  children,
  type,
}) => {
  return (
    <div
      className={`alert alert-dismissible alert-${type} margin-bottom-15 margin-top-15`}
    >
      <div className="display-flex">
        <span
          className={`text-color-${type} text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-exclamation-sign`}
        />
        <div>{children}</div>
      </div>
    </div>
  );
};
