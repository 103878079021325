import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import { TransactionWithVehicle } from '../../../models';

type TransactionsUiState = {
  sidebar: {
    transactionWithVehicle?: TransactionWithVehicle;
    open: boolean;
  };
};

const initialState: TransactionsUiState = {
  sidebar: {
    open: false,
  },
};

export const transactionsUiSlice = createSlice({
  name: 'transactionsPage',
  initialState,
  reducers: {
    setSidebarTransaction: (
      state,
      action: PayloadAction<TransactionWithVehicle>,
    ) => {
      state.sidebar.transactionWithVehicle = action.payload;
    },
    openSidebar: (state) => {
      if (state.sidebar.transactionWithVehicle) {
        state.sidebar.open = true;
      }
    },
    closeSidebar: (state) => {
      state.sidebar.open = false;
    },
  },
});

export const { openSidebar, closeSidebar, setSidebarTransaction } =
  transactionsUiSlice.actions;

export const getSidebarTransaction = (state: RootState) =>
  state.transactionsUiState.sidebar.transactionWithVehicle;

export const isSidebarOpen = (state: RootState) =>
  state.transactionsUiState.sidebar.open;
