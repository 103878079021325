import {
  enhancedApi,
  ServiceActivation,
  useRemovePaymentMethodMutation,
  useUpdateServiceActivationsMutation,
} from '../../../../codegen/vehicles';
import React from 'react';
import { CustomFormattedMessage, useCustomIntl } from '../../../../i18n/i18n';
import map from 'lodash/map';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { forEach, remove } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { useVehicleDialogFormContext } from './ServiceConfigurationForm';
import {
  closeRemoveConfirmationDialog,
  getPayloadRemoveConfirmationDialog,
  getSelectedVehicle,
  isRemoveConfirmationDialogOpen,
} from '../../state/onboardingUiSlice';

type RemoveConfirmationDialogProps = {};

export const RemoveConfirmationDialog: React.FC<
  RemoveConfirmationDialogProps
> = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const showDialog = useAppSelector(isRemoveConfirmationDialogOpen);
  const vehicle = useAppSelector(getSelectedVehicle);
  const { linkedServiceActivations, paymentMethodId } = useAppSelector(
    getPayloadRemoveConfirmationDialog,
  );
  const {
    removePaymentMethod: removePaymentMethodFromForm,
    getServiceActivations,
    assignPaymentMethodToServiceActivation,
    setServiceActivationActive,
  } = useVehicleDialogFormContext();
  const [
    updateServiceActivations,
    { isLoading: isUpdateServiceActivationLoading },
  ] = useUpdateServiceActivationsMutation();
  const [removePaymentMethod, { isLoading: isRemovePaymentMethodLoading }] =
    useRemovePaymentMethodMutation();

  if (!showDialog || !paymentMethodId) {
    return <></>;
  }

  const doRemovePaymentMethod = () => {
    removePaymentMethod({
      vehicleId: vehicle!.id,
      paymentMethodId,
    }).then((result) => {
      if ('data' in result) {
        removePaymentMethodFromForm(paymentMethodId);
        dispatch(
          enhancedApi.util.updateQueryData(
            'getVehicles',
            undefined,
            (draftVehicles) => {
              draftVehicles.items = draftVehicles.items.map((v) => {
                if (v.id === v!.id) {
                  remove(v.paymentMethods, (pm) => {
                    return pm.id === paymentMethodId;
                  });
                  v.serviceActivations = v.serviceActivations.map(
                    (serviceActivation) => {
                      if (
                        serviceActivation.paymentMethodAssignments[0]
                          ?.paymentMethodId === paymentMethodId
                      ) {
                        serviceActivation.enabled = false;
                        serviceActivation.paymentMethodAssignments = [];
                      }
                      return serviceActivation;
                    },
                  );
                }
                return v;
              });
            },
          ),
        );
        handleClose();
      }
    });
  };

  const doRemoveCard = () => {
    forEach(linkedServiceActivations, (serviceActivation) => {
      assignPaymentMethodToServiceActivation(serviceActivation.serviceType, '');
      setServiceActivationActive(serviceActivation.serviceType, false);
    });
    updateServiceActivations({
      vehicleId: vehicle!.id,
      updateServiceActivationsRequest: {
        serviceActivations: Object.values(getServiceActivations()),
      },
    }).then((result: any) => {
      if (result.data) {
        doRemovePaymentMethod();
      }
    });
  };

  const handleClose = () => {
    dispatch(closeRemoveConfirmationDialog());
  };

  const renderRemoveConfirmationBody = () => {
    return (
      <>
        <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
          <CustomFormattedMessage
            id={'onboarding.dialog.removeCardConfirmation.message'}
          />
        </div>
        <ul>
          {map(
            linkedServiceActivations,
            (serviceActivation: ServiceActivation) => {
              return (
                <li className={'margin-top-10 margin-bottom-10'}>
                  <ServiceRepresentation
                    serviceType={serviceActivation.serviceType}
                    representationSize="large"
                  />
                </li>
              );
            },
          )}
        </ul>
        <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
          <CustomFormattedMessage
            id={'onboarding.dialog.removeCardConfirmation.question'}
          />
        </div>
      </>
    );
  };

  const renderRemoveConfirmationFooter = (isLoading: boolean) => {
    const loadingClass = isLoading ? 'btn-loading' : '';
    return (
      <>
        <Button
          data-testid={'onboarding-dialog-body-cancelRemoveServiceCardButton'}
          bsStyle={'default'}
          type={'button'}
          className={'margin-right-10'}
          onClick={() => handleClose()}
          disabled={isLoading}
        >
          <CustomFormattedMessage
            id={'onboarding.dialog.removeCardConfirmation.no'}
          />
        </Button>
        <Button
          data-testid={'onboarding-dialog-body-confirmRemoveServiceCardButton'}
          bsStyle={'primary'}
          type={'submit'}
          className={loadingClass}
          onClick={() => doRemoveCard()}
          disabled={isLoading}
        >
          <CustomFormattedMessage
            id={'onboarding.dialog.removeCardConfirmation.yes'}
          />
        </Button>
      </>
    );
  };

  return (
    <Dialog
      aria-label={'delete-payment-method-warning'}
      useOverflow
      bsSize={Dialog.SIZE_SM}
      show
      onHide={() => handleClose()}
      title={intl.formatMessage({
        id: 'onboarding.dialog.removeCardConfirmation.title',
      })}
      body={renderRemoveConfirmationBody()}
      footer={renderRemoveConfirmationFooter(
        isUpdateServiceActivationLoading || isRemovePaymentMethodLoading,
      )}
    />
  );
};
