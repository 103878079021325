import React from 'react';
import { ServiceRepresentation } from '../../../../../components/dataRepresentation/ServiceRepresentation';
import { SIDEBAR_DATA_COLOR } from '../VehicleSidebar';
import { ServiceType } from '../../../../../codegen/vehicles';

type ServiceRowProps = {
  service: ServiceType;
};

export const ServiceRow: React.FC<ServiceRowProps> = ({ service }) => {
  return (
    <tr>
      <td
        colSpan={2}
        className={`text-size-20 ${SIDEBAR_DATA_COLOR} border-top-0 padding-top-20 white-space-nowrap`}
      >
        <ServiceRepresentation
          serviceType={service}
          representationSize="large"
        />
      </td>
    </tr>
  );
};
