import { CustomFormattedMessage } from '../../../../i18n/i18n';

type CancelDialogBodyProps = {
  isError: boolean;
  isSuccess: boolean;
};

export const CancelDialogBody = ({
  isError,
  isSuccess,
}: CancelDialogBodyProps) => {
  if (isError) {
    return (
      <>
        <p className="text-size-h4">
          <span className="rioglyph rioglyph-remove-sign margin-right-10" />
          <CustomFormattedMessage
            id={'common.booking.cancel.dialog.failure.headline'}
          />
        </p>
        <p>
          <CustomFormattedMessage
            id={'common.booking.cancel.dialog.failure.text'}
          />
        </p>
      </>
    );
  }

  if (isSuccess) {
    return (
      <p className="text-size-h4">
        <span className="rioglyph rioglyph-ok-sign margin-right-10" />
        <CustomFormattedMessage
          id={'common.booking.cancel.dialog.success.headline'}
        />
      </p>
    );
  }

  return (
    <>
      <p>
        <CustomFormattedMessage
          id={'common.booking.cancel.dialog.confirmation.text.line1'}
        />
      </p>
      <p>
        <CustomFormattedMessage
          id={'common.booking.cancel.dialog.confirmation.text.line2'}
        />
      </p>
      <p>
        <CustomFormattedMessage
          id={'common.booking.cancel.dialog.confirmation.text.line3'}
        />
      </p>
    </>
  );
};
