import { FeatureTeaser } from '../FeatureTeaser';
import fleetMonitorImage from '../../../assets/images/landingPage/fleet-monitor.svg';

import { CustomFormattedMessage } from '../../../i18n/i18n';

export const LiveMonitorTeaser = () => {
  return (
    <FeatureTeaser
      image={fleetMonitorImage}
      headline={
        <CustomFormattedMessage
          id={'landing.partnerTeaser.fleetMonitor.headline'}
        />
      }
      content={
        <CustomFormattedMessage
          id={'landing.partnerTeaser.fleetMonitor.body'}
        />
      }
      onClick={() => {
        window.open('https://livemonitor.rio.cloud', '_blank', 'noopener');
      }}
    />
  );
};
