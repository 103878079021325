import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  getSelectedBooking,
  isBookingSidebarOpen,
  openCancelDialogue,
  unselectBooking,
} from '../../state/bookingsUiSlice';
import { useLocation } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { BookingDetailsTable } from './BookingDetailsTable';
import { SidebarVehicleHeadline } from '../../../../components/sidebar/SidebarVehicleHeadline';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { CancelDialog } from '../cancelDialog/CancelDialog';
import { BookingStatus, ServiceType } from '../../../../codegen/bookings';
import { BOOKINGS_ROUTE } from '../../../../routes/routes';

export const BookingSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector(isBookingSidebarOpen);
  const bookingWithVehicle = useAppSelector(getSelectedBooking);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== BOOKINGS_ROUTE) {
      dispatch(unselectBooking());
    }
  }, [location.pathname]);

  const handleClose = () => {
    dispatch(unselectBooking());
  };

  const handleOpenDialog = () => {
    dispatch(openCancelDialogue());
  };

  if (!isSidebarOpen || !bookingWithVehicle) {
    return <></>;
  }

  const { vehicle } = bookingWithVehicle;

  const bookingCanBeCanceled =
    !!bookingWithVehicle &&
    bookingWithVehicle.serviceType === ServiceType.Parking &&
    bookingWithVehicle.status === BookingStatus.Planned;

  return (
    <ApplicationLayout.Sidebar
      className={'right'}
      data-testid={'bookings-sidebar'}
    >
      <Sidebar
        title={<CustomFormattedMessage id={'bookings.sidebar.title'} />}
        titleClassName={
          'padding-left-10 text-light text-uppercase text-size-16'
        }
        onClose={handleClose}
        resizable
        position={'right'}
        width={400}
        minWidth={330}
        maxWidth={600}
      >
        <div className={'padding-left-20 padding-right-20'}>
          <SidebarVehicleHeadline vehicleWithGroups={vehicle!} />
          <BookingDetailsTable booking={bookingWithVehicle} />
          {bookingCanBeCanceled && (
            <div className="display-flex justify-content-end margin-top-25">
              <Button
                data-testid="booking-cancel-button"
                className="btn btn-primary"
                onClick={handleOpenDialog}
              >
                <CustomFormattedMessage id={'common.booking.button.cancel'} />
              </Button>
            </div>
          )}
          <CancelDialog />
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};
