import React from 'react';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { TRANSACTION_STATE_TO_TRANSLATION_KEY } from '../../../i18n/enumMappings';
import { TransactionState } from '../../../codegen/transactions';

type TransactionStateLabelProps = {
  state: TransactionState;
};

export const TransactionStateLabel: React.FC<TransactionStateLabelProps> = ({
  state,
}) => {
  const getColor = () => {
    switch (state) {
      case TransactionState.Created:
      case TransactionState.InProgress:
        return 'primary';
      case TransactionState.Delivered:
      case TransactionState.Completed:
        return 'success';
      case TransactionState.Failed:
        return 'danger';
      default:
        return 'default';
    }
  };

  return (
    <>
      <span className={`label label-condensed label-${getColor()}`}>
        <CustomFormattedMessage
          id={TRANSACTION_STATE_TO_TRANSLATION_KEY[state]}
        />
      </span>
    </>
  );
};
