import React, { ReactNode } from 'react';

type FormFieldProps = {
  hasError?: boolean;
  className?: string;
  errorMessage?: string | React.ReactElement;
  children?: ReactNode;
};

export const FormField: React.FC<FormFieldProps> = ({
  hasError,
  errorMessage,
  children,
  className,
}) => {
  return (
    <div
      className={`form-group ${
        hasError ? 'has-feedback has-error' : ''
      } ${className}`}
    >
      {children}
      {hasError && errorMessage && (
        <>
          <span className="form-control-feedback rioglyph rioglyph-error-sign" />
          <span
            className="help-block position-relative"
            data-testid={'common-formField-errorMessage'}
          >
            <span>{errorMessage}</span>
          </span>
        </>
      )}
    </div>
  );
};
