import { DashboardPanel } from '../DashboardPanel';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { InfoPopup } from '../../../../components/InfoPopup';
import { LoadingPanel } from '../../../../components/LoadingPanel';
import { NoDataPanelBody } from '../../../../components/NoDataPanelBody';
import { Top5Entries } from './Top5Entries';
import { useTop5CostsPanel } from './useTop5CostsPanel';

export enum Top5CostsPanelType {
  LOWEST = 'lowest',
  HIGHEST = 'highest',
}

type Props = {
  type: Top5CostsPanelType;
};

export const Top5CostsPanel = ({ type }: Props) => {
  const {
    top5LowestCostVehicles,
    top5HighestCostVehicles,
    sameTop5Vehicles,
    currency,
    isLoading,
    noData,
  } = useTop5CostsPanel();

  const top5Vehicles =
    type === Top5CostsPanelType.LOWEST
      ? top5LowestCostVehicles
      : top5HighestCostVehicles;

  if (
    !isLoading &&
    !noData &&
    type === Top5CostsPanelType.HIGHEST &&
    sameTop5Vehicles
  ) {
    return <></>;
  }

  return (
    <DashboardPanel
      testId={`dashboard-top5-${type}-panel`}
      header={
        <div className="display-flex align-items-center">
          <CustomFormattedMessage
            id={`dashboard.panels.top5costs.${type}.header`}
          />
          <div
            data-testid={`dashboard-top5-costs-${type}-information-icon-wrapper`}
            className="margin-left-5"
          >
            <InfoPopup
              dataTestid={`dashboard-top5-costs-${type}-information-tooltip`}
              marginTop={0}
              placement={'right'}
            >
              <CustomFormattedMessage
                id={`dashboard.panels.top5costs.${type}.informationTooltip`}
              />
            </InfoPopup>
          </div>
        </div>
      }
      body={
        <LoadingPanel loading={isLoading}>
          <div data-testid={`dashboard-top5-costs-${type}-content`}>
            {noData ? (
              <NoDataPanelBody />
            ) : (
              <Top5Entries
                type={type}
                top5Vehicles={top5Vehicles}
                currency={currency}
              />
            )}
          </div>
        </LoadingPanel>
      }
    />
  );
};
