import React from 'react';

type DashboardPanelProps = {
  header?: string | React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
  testId?: string;
};

export const DashboardPanel: React.FC<DashboardPanelProps> = ({
  header,
  body,
  footer,
  testId,
}) => {
  const width = 350;

  const contentHeight = header ? 191 : 248;

  return (
    <div
      className="panel panel-default height-250"
      style={{
        width,
      }}
      data-testid={testId}
    >
      {header && (
        <div className="panel-heading border-bottom-0 text-size-18 text-light padding-15 padding-left-20">
          {header}
        </div>
      )}
      <div
        className={'display-flex flex-column justify-content-between'}
        style={{ height: contentHeight }}
      >
        <div className="panel-body margin-0 padding-0">{body}</div>
        {footer && <div className="panel-footer border-top-0">{footer}</div>}
      </div>
    </div>
  );
};
