import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';

export const NoServices: React.FC = () => {
  return (
    <div className={'text-size-18 text-center text-color-light margin-top-50'}>
      <div className={'text-uppercase'}>
        <CustomFormattedMessage id="onboarding.sidebar.body.noServices" />
      </div>
    </div>
  );
};
