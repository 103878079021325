import React from 'react';
import { CustomFormattedMessage } from '../../../../../i18n/i18n';
import { SIDEBAR_DATA_COLOR, SIDEBAR_LABEL_COLOR } from '../VehicleSidebar';
import { MaskedCardNumber } from '../../../../../components/MaskedCardNumber';
import { ServiceCardRepresentation } from '../../../../../components/dataRepresentation/ServiceCardRepresentation';
import {
  PaymentMethodType,
  PaymentPartnerType,
} from '../../../../../codegen/vehicles';

interface IActiveServiceItemProps {
  paymentMethodType?: PaymentMethodType | null;
  paymentPartner?: PaymentPartnerType | null;
  lastFourDigits?: string | null;
  expiry?: string | null;
}

export const CardDetails: React.FC<IActiveServiceItemProps> = ({
  paymentMethodType,
  paymentPartner,
  lastFourDigits,
  expiry,
}) => {
  {
    return (
      <>
        {paymentMethodType && (
          <tr>
            <td className={`${SIDEBAR_LABEL_COLOR}`}>
              <CustomFormattedMessage id={'common.card.type'} />
            </td>
            <td className={`${SIDEBAR_DATA_COLOR}`}>
              <ServiceCardRepresentation paymentPartner={paymentPartner!} />
            </td>
          </tr>
        )}
        {lastFourDigits && (
          <tr>
            <td className={`${SIDEBAR_LABEL_COLOR}`}>
              <CustomFormattedMessage id={'common.card.number'} />
            </td>
            <td className={`${SIDEBAR_DATA_COLOR}`}>
              <MaskedCardNumber lastFourDigits={lastFourDigits} />
            </td>
          </tr>
        )}
        {expiry && (
          <tr>
            <td className={`${SIDEBAR_LABEL_COLOR}`}>
              <CustomFormattedMessage id={'common.card.expiryDate'} />
            </td>
            <td className={`${SIDEBAR_DATA_COLOR}`}>{expiry ? expiry : ''}</td>
          </tr>
        )}
      </>
    );
  }
};
