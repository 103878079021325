import { ReactNode } from 'react';
import Teaser from '@rio-cloud/rio-uikit/Teaser';

type FeatureTeaserProps = {
  content: ReactNode;
  headline: ReactNode;
  image: string;
  onClick?: () => void;
};

export const FeatureTeaser = ({
  content,
  image,
  headline,
  onClick,
}: FeatureTeaserProps) => {
  return (
    <div
      onClick={onClick}
      className={'cursor-pointer'}
    >
      <Teaser
        className={'margin-right-10 margin-left-10 hover-bg-highlight-decent'}
        headline={
          <span className={'text-size-h4 text-color-primary'}>{headline}</span>
        }
        content={content}
        image={{ src: image, aspectRatio: '3:2' }}
      />
    </div>
  );
};
