import { useLocation, useNavigate } from 'react-router-dom';
import {
  DialogMode,
  openAddNewServiceCardDialog,
  openConfigurationDialog,
  openSidebar,
  setSelectedVehicle,
} from '../pages/onboarding/state/onboardingUiSlice';
import { PaymentPartnerType, Vehicle } from '../codegen/vehicles';
import { useVehicles } from '../state/vehicles/useVehicles';
import { useAppDispatch } from '../state/hooks';
import { VehicleWithGroups } from '../models';
import { useEffect } from 'react';
import { ONBOARDING_ROUTE } from './routes';

export const useCheckDeeplinking = () => {
  const { vehicles, isLoading } = useVehicles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);

  const PARAMETER_SHOW_CONFIGURATION_DIALOG = 'editDialog';
  const PARAMETER_SHOW_ADD_NEW_SERVICE_CARD_DIALOG = 'addNewCardDialog';
  const PARAMETER_EXTERNAL_LOGIN_ERROR = 'externalLoginError';

  const selectVehicle = (vehicle: Vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    dispatch(openSidebar());
  };

  const getVehicle = (): VehicleWithGroups | undefined => {
    const vehicleId = queryParams.get('vehicleId');

    if (!vehicleId || vehicles.length < 1) {
      return undefined;
    }

    return vehicles.find((v) => v.id === vehicleId);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (path === ONBOARDING_ROUTE) {
      const vehicle = getVehicle();
      if (vehicle) {
        selectVehicle(vehicle);

        const dialogMode = queryParams.get(PARAMETER_SHOW_CONFIGURATION_DIALOG);
        if (dialogMode) {
          dispatch(openConfigurationDialog(dialogMode as DialogMode));

          const showAddNewCardDialogValue = queryParams.get(
            PARAMETER_SHOW_ADD_NEW_SERVICE_CARD_DIALOG,
          );
          if (showAddNewCardDialogValue) {
            dispatch(
              openAddNewServiceCardDialog({
                paymentPartnerType:
                  showAddNewCardDialogValue as PaymentPartnerType,
                externalLoginError:
                  queryParams.get(PARAMETER_EXTERNAL_LOGIN_ERROR) || '',
              }),
            );
          }
        }
        navigate(ONBOARDING_ROUTE);
        return;
      }
    }

    navigate(path);
  }, [isLoading]);
};
