import { createApi } from '@reduxjs/toolkit/query/react';
import { restBaseQuery } from '../../configuration/baseQuery';
import { appConfiguration } from '../../configuration/appConfiguration';

export const locationsApiTemplateSlice = createApi({
  reducerPath: 'locationsApi',
  baseQuery: restBaseQuery(appConfiguration.location.api.url),
  endpoints: () => ({}),
});

export const {
  reducer: locationsReducer,
  reducerPath: locationsReducerPath,
  middleware: locationsMiddleware,
} = locationsApiTemplateSlice;
