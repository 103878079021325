import React from 'react';
import { ServiceRow } from './ServiceRow';
import {
  PaymentMethod,
  PaymentMethodAssignment,
  ServiceActivation,
} from '../../../../../codegen/vehicles';
import map from 'lodash/map';
import find from 'lodash/find';
import { PaymentMethodDetails } from './PaymentMethodDetails';

type ActiveServiceProps = {
  activeService: ServiceActivation;
  paymentMethods: PaymentMethod[];
};

export const ActiveService: React.FC<ActiveServiceProps> = ({
  activeService,
  paymentMethods,
}) => {
  return (
    <>
      <ServiceRow service={activeService.serviceType} />
      {map(
        activeService.paymentMethodAssignments,
        (paymentMethodAssignment: PaymentMethodAssignment) => {
          const paymentMethod = find(paymentMethods, {
            id: paymentMethodAssignment.paymentMethodId,
          });
          if (paymentMethod)
            return (
              <PaymentMethodDetails
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
              />
            );
        },
      )}
    </>
  );
};
