import React from 'react';
import { BookingStatus } from '../../../codegen/bookings';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { BOOKING_STATUS_TO_TRANSLATION_KEY } from '../../../i18n/enumMappings';

type BookingStatusLabelProps = {
  status: BookingStatus;
};

export const BookingStatusLabel: React.FC<BookingStatusLabelProps> = ({
  status,
}) => {
  const getColor = () => {
    switch (status) {
      case BookingStatus.Planned:
      case BookingStatus.InProgress:
        return 'primary';
      case BookingStatus.Completed:
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <span className={`label label-condensed label-${getColor()}`}>
      <CustomFormattedMessage id={BOOKING_STATUS_TO_TRANSLATION_KEY[status]} />
    </span>
  );
};
