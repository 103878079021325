import { useAppDispatch, useAppSelector } from '../state/hooks';
import { getLocale, setLocale as setLocaleState } from './localeSlice';

export const useLocale = () => {
  const locale = useAppSelector(getLocale);
  const dispatch = useAppDispatch();
  const setLocale = (newlocale: string) => {
    const html = document.querySelector('html');

    if (html && newlocale && html.getAttribute('lang') !== newlocale) {
      html.setAttribute('lang', newlocale);
    }

    dispatch(setLocaleState(newlocale));
  };

  return {
    locale,
    setLocale,
  };
};
