import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingWithVehicle } from '../../../models';
import { RootState } from '../../../state/store';

type BookingsUiState = {
  selectedBooking?: BookingWithVehicle;
  sidebar: {
    open: boolean;
  };
  dialog: {
    open: boolean;
  };
};

const initialState: BookingsUiState = {
  sidebar: {
    open: false,
  },
  dialog: {
    open: false,
  },
};

export const bookingsUiSlice = createSlice({
  name: 'bookingsPage',
  initialState,
  reducers: {
    selectBooking: (state, action: PayloadAction<BookingWithVehicle>) => {
      state.selectedBooking = action.payload;
      state.sidebar.open = true;
    },
    unselectBooking: (state) => {
      state.selectedBooking = undefined;
      state.sidebar.open = false;
    },
    openCancelDialogue: (state) => {
      state.dialog.open = true;
    },
    closeCancelDialogue: (state) => {
      state.dialog.open = false;
    },
  },
});

export const {
  selectBooking,
  unselectBooking,
  openCancelDialogue,
  closeCancelDialogue,
} = bookingsUiSlice.actions;

export const getSelectedBooking = (state: RootState) =>
  state.bookingsUiState.selectedBooking;

export const isBookingSidebarOpen = (state: RootState) =>
  state.bookingsUiState.sidebar.open;

export const isBookingDialogOpen = (state: RootState) =>
  state.bookingsUiState.dialog.open;
